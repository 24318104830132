/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./menu.component";
import * as i4 from "../link.service";
var styles_MenuComponent = [i0.styles];
var RenderType_MenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuComponent, data: {} });
export { RenderType_MenuComponent as RenderType_MenuComponent };
function View_MenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["rel", "noopener"], ["target", "_blank"]], [[8, "href", 4], [8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(2, null, [" ", " "]))], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.menuItemClass; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.menuUrl, ""); var currVal_1 = i1.ɵinlineInterpolate(2, "menu-external-item-", _co.itemType, "-", _v.parent.context.$implicit.menuId, ""); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.context.$implicit.menuName; _ck(_v, 2, 0, currVal_3); }); }
function View_MenuComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["rel", "noopener"]], [[8, "href", 4], [8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(2, null, [" ", " "]))], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.menuItemClass; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.menuUrl, ""); var currVal_1 = i1.ɵinlineInterpolate(2, "menu-external-item-", _co.itemType, "-", _v.parent.context.$implicit.menuId, ""); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.context.$implicit.menuName; _ck(_v, 2, 0, currVal_3); }); }
function View_MenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.isExternal; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.$implicit.isExternal; _ck(_v, 4, 0, currVal_1); }, null); }
function View_MenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ul", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuComponent_2)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menu.menuClass; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.menu.menuItems; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_MenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menu; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vmc-menu", [], null, null, null, View_MenuComponent_0, RenderType_MenuComponent)), i1.ɵdid(1, 638976, null, 0, i3.MenuComponent, [i4.LinkService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MenuComponentNgFactory = i1.ɵccf("vmc-menu", i3.MenuComponent, View_MenuComponent_Host_0, { menu: "menu", itemClass: "itemClass", itemType: "itemType" }, {}, []);
export { MenuComponentNgFactory as MenuComponentNgFactory };

import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, map, retryWhen } from 'rxjs/operators';
var BrandService = /** @class */ (function (_super) {
    tslib_1.__extends(BrandService, _super);
    function BrandService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BrandService.prototype.search = function (filters, options) {
        var url = this.url + 'brand/filter' + ApiService.httpBuildQuery(options);
        return this.http.post(url, filters, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('BrandService::search, url=' + url + ', filters=' + JSON.stringify(filters), {}, {
            url: url,
            method: 'POST',
            headers: this.getHeadersData(),
            data: filters,
        })), map(function (response) { return response.data; }));
    };
    return BrandService;
}(ApiService));
export { BrandService };

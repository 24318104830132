/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header-default/header-default.component.ngfactory";
import * as i3 from "./header-default/header-default.component";
import * as i4 from "./header-agent/header-agent.component.ngfactory";
import * as i5 from "./header-agent/header-agent.component";
import * as i6 from "../../auth/authentication.service";
import * as i7 from "@angular/material/dialog";
import * as i8 from "@angular/router";
import * as i9 from "./header-consumer/header-consumer.component.ngfactory";
import * as i10 from "./header-consumer/header-consumer.component";
import * as i11 from "@angular/common";
import * as i12 from "./header.component";
import * as i13 from "../is-content.service";
import * as i14 from "../link.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vmc-header-default", [], null, [[null, "onMenuToggle"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onMenuToggle" === en)) {
        var pd_0 = (_co.toggleMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_HeaderDefaultComponent_0, i2.RenderType_HeaderDefaultComponent)), i1.ɵdid(1, 49152, null, 0, i3.HeaderDefaultComponent, [], { isMenuOpened: [0, "isMenuOpened"], home_link: [1, "home_link"], content: [2, "content"] }, { onMenuToggle: "onMenuToggle" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMenuOpened; var currVal_1 = _co.home_link; var currVal_2 = _co.content; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_HeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vmc-header-agent", [], null, [[null, "onContactUs"], [null, "onMenuToggle"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onContactUs" === en)) {
        var pd_0 = (_co.showContact() !== false);
        ad = (pd_0 && ad);
    } if (("onMenuToggle" === en)) {
        var pd_1 = (_co.toggleMenu() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_HeaderAgentComponent_0, i4.RenderType_HeaderAgentComponent)), i1.ɵdid(1, 770048, null, 0, i5.HeaderAgentComponent, [i6.AuthenticationService, i7.MatDialog, i8.Router], { canManageBranches: [0, "canManageBranches"], content: [1, "content"], currentUser: [2, "currentUser"], home_link: [3, "home_link"], isMenuOpened: [4, "isMenuOpened"], showBrochure: [5, "showBrochure"], canManageClients: [6, "canManageClients"], canManageClientContacts: [7, "canManageClientContacts"], canViewDashboard: [8, "canViewDashboard"], canViewReportsPage: [9, "canViewReportsPage"], isClientUserHome: [10, "isClientUserHome"], canManageClientUsers: [11, "canManageClientUsers"], isCompanyConveyancer: [12, "isCompanyConveyancer"], isIndividualConveyancer: [13, "isIndividualConveyancer"], isIndividualConsumer: [14, "isIndividualConsumer"] }, { onContactUs: "onContactUs", onMenuToggle: "onMenuToggle" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.canManageBranches; var currVal_1 = _co.content; var currVal_2 = _co.currentUser; var currVal_3 = _co.home_link; var currVal_4 = _co.isMenuOpened; var currVal_5 = _co.showBrochure; var currVal_6 = _co.canManageClients; var currVal_7 = _co.canManageClientContacts; var currVal_8 = _co.canViewDashboard; var currVal_9 = _co.canViewReportsPage; var currVal_10 = _co.isClientUserHome; var currVal_11 = _co.canManageClientUsers; var currVal_12 = _co.isCompanyConveyancer; var currVal_13 = _co.isIndividualConveyancer; var currVal_14 = _co.isIndividualConsumer; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14]); }, null); }
function View_HeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vmc-header-consumer", [], null, [[null, "onContactUs"], [null, "onMenuToggle"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onContactUs" === en)) {
        var pd_0 = (_co.showContact() !== false);
        ad = (pd_0 && ad);
    } if (("onMenuToggle" === en)) {
        var pd_1 = (_co.toggleMenu() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_HeaderConsumerComponent_0, i9.RenderType_HeaderConsumerComponent)), i1.ɵdid(1, 245760, null, 0, i10.HeaderConsumerComponent, [i6.AuthenticationService, i7.MatDialog], { content: [0, "content"], currentUser: [1, "currentUser"], isMenuOpened: [2, "isMenuOpened"], showBrochure: [3, "showBrochure"] }, { onContactUs: "onContactUs", onMenuToggle: "onMenuToggle" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content; var currVal_1 = _co.currentUser; var currVal_2 = _co.isMenuOpened; var currVal_3 = _co.showBrochure; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_3)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_4)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isConsumer; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isConsumer; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loggedIn(); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.loggedIn(); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vmc-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i12.HeaderComponent, [i6.AuthenticationService, i8.Router, i13.IsContentService, i14.LinkService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("vmc-header", i12.HeaderComponent, View_HeaderComponent_Host_0, {}, { onContactUs: "onContactUs", openMenuEmitter: "openMenuEmitter" }, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };

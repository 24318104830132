import * as tslib_1 from "tslib";
import { ApiResponse } from '../api-services/api-response';
var AddressCollection = /** @class */ (function (_super) {
    tslib_1.__extends(AddressCollection, _super);
    function AddressCollection() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return AddressCollection;
}(ApiResponse));
export { AddressCollection };
var AddressItem = /** @class */ (function (_super) {
    tslib_1.__extends(AddressItem, _super);
    function AddressItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return AddressItem;
}(ApiResponse));
export { AddressItem };
var AddressRelations = /** @class */ (function () {
    function AddressRelations() {
    }
    return AddressRelations;
}());
export { AddressRelations };
var Address = /** @class */ (function () {
    function Address() {
        this.id = 0;
        this.type = 'address';
        this.attributes = new AddressAttributes();
        this.relationships = new AddressRelations();
    }
    return Address;
}());
export { Address };
var AddressAttributes = /** @class */ (function () {
    function AddressAttributes() {
        this.full_address = '';
        this.address_line_1 = '';
        this.post_town = '';
        this.street = '';
        this.area = '';
        this.postcode = '';
        this.is_parent = false;
        this.created_at = 0;
        this.updated_at = 0;
    }
    return AddressAttributes;
}());
export { AddressAttributes };

<div>
  <div class="content">
    <h3 *ngIf="title">{{ title }}</h3>
    <p>{{ body }}</p>
  </div>
  <div class="columns is-multiline is-mobile">
    <div class="column is-12" *ngIf="errors.length > 0">
      <div *ngFor="let error of errors">
        <p class="help is-danger is-marginless">{{ error }}</p>
      </div>
    </div>

    <div class="column is-half-mobile">
      <mat-form-field>
        <mat-select placeholder="Day" [(ngModel)]="day" name="Day" id="confirm-with-datepicker-day">
          <mat-option *ngFor="let i of days" [value]="i" id="confirm-with-datepicker-day-{{ i }}">{{i}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="column is-half-mobile">
      <mat-form-field>
        <mat-select placeholder="Month" [(ngModel)]="month" name="Month" id="confirm-with-datepicker-month">
          <mat-option [value]="1" id="confirm-with-datepicker-month-1">January</mat-option>
          <mat-option [value]="2" id="confirm-with-datepicker-month-2">February</mat-option>
          <mat-option [value]="3" id="confirm-with-datepicker-month-3">March</mat-option>
          <mat-option [value]="4" id="confirm-with-datepicker-month-4">April</mat-option>
          <mat-option [value]="5" id="confirm-with-datepicker-month-5">May</mat-option>
          <mat-option [value]="6" id="confirm-with-datepicker-month-6">June</mat-option>
          <mat-option [value]="7" id="confirm-with-datepicker-month-7">July</mat-option>
          <mat-option [value]="8" id="confirm-with-datepicker-month-8">August</mat-option>
          <mat-option [value]="9" id="confirm-with-datepicker-month-9">September</mat-option>
          <mat-option [value]="10" id="confirm-with-datepicker-month-10">October</mat-option>
          <mat-option [value]="11" id="confirm-with-datepicker-month-11">November</mat-option>
          <mat-option [value]="12" id="confirm-with-datepicker-month-12">December</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="column">
      <mat-form-field>
        <mat-select placeholder="Year" [(ngModel)]="year" name="Year" id="confirm-with-datepicker-year">
          <mat-option *ngFor="let i of years" [value]="i" id="confirm-with-datepicker-year-{{ i }}">
            {{i}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button class="button is-primary" [ngClass]="{'is-loading': formProcessing}" (click)="onSubmitClicked()"
            id="confirm-with-datepicker-submit">{{ submitButtonLabel }}</button>
    <button mat-button [mat-dialog-close]="false" id="confirm-with-datepicker-cancel">{{ cancelButtonLabel }}</button>
  </div>
</div>

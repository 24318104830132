import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, retryWhen } from 'rxjs/operators';
var ExchangeReadyService = /** @class */ (function (_super) {
    tslib_1.__extends(ExchangeReadyService, _super);
    function ExchangeReadyService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ExchangeReadyService.prototype.get = function (listingId) {
        var url = this.url + 'listing/' + listingId + '/exchange-ready';
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ExchangeReadyService::get, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    return ExchangeReadyService;
}(ApiService));
export { ExchangeReadyService };

import * as tslib_1 from "tslib";
import { Base } from './base';
var Partner = /** @class */ (function (_super) {
    tslib_1.__extends(Partner, _super);
    function Partner(data) {
        if (data === void 0) { data = null; }
        var _this = _super.call(this, data) || this;
        _this.partnerName = '';
        _this.partnerImage = '';
        _this.partnerUrl = '';
        _this.partnerName = _this.propertyIsValid(data, 'partnerName') ? data.partnerName : '';
        _this.partnerImage = _this.propertyIsValid(data, 'partnerImage') ? data.partnerImage : '';
        _this.partnerUrl = _this.propertyIsValid(data, 'partnerUrl') ? data.partnerUrl : '';
        return _this;
    }
    return Partner;
}(Base));
export { Partner };

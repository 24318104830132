import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, retryWhen } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../shared/error-handler/error-handler.service";
var TeamMembersService = /** @class */ (function (_super) {
    tslib_1.__extends(TeamMembersService, _super);
    function TeamMembersService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TeamMembersService.prototype.members = function () {
        var url = this.url + 'settings/teams/members';
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('TeamMembersService::members, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    TeamMembersService.ngInjectableDef = i0.defineInjectable({ factory: function TeamMembersService_Factory() { return new TeamMembersService(i0.inject(i1.HttpClient), i0.inject(i2.ErrorHandlerService)); }, token: TeamMembersService, providedIn: "root" });
    return TeamMembersService;
}(ApiService));
export { TeamMembersService };

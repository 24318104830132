import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, retryWhen } from 'rxjs/operators';
var MilestoneGroupBranchService = /** @class */ (function (_super) {
    tslib_1.__extends(MilestoneGroupBranchService, _super);
    function MilestoneGroupBranchService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MilestoneGroupBranchService.prototype.workflow = function (milestone_group_id, branch_id, listing_id, options) {
        if (options === void 0) { options = {}; }
        var url = this.url +
            'milestone_group/' +
            milestone_group_id +
            '/branch/' +
            branch_id +
            '/listing/' +
            listing_id +
            '/workflow';
        url = url + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('MilestoneGroupBranchService::workflow, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    MilestoneGroupBranchService.prototype.getMilestoneTypes = function (milestoneGroupId, branchId, offerListingId, include) {
        var url = this.url +
            'offers/milestone-types';
        url = url + ApiService.httpBuildQuery();
        var data = {
            milestone_group_id: milestoneGroupId,
            branch_id: branchId,
            offer_listing_id: offerListingId,
            include: include
        };
        return this.http.post(url, data, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('MilestoneGroupBranchService::getMilestoneTypes, url=' + url, {}, {
            url: url,
            method: 'POST',
            headers: this.getHeadersData(),
            data: data
        })));
    };
    return MilestoneGroupBranchService;
}(ApiService));
export { MilestoneGroupBranchService };

import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, retryWhen } from 'rxjs/operators';
var ClientMortgagesService = /** @class */ (function (_super) {
    tslib_1.__extends(ClientMortgagesService, _super);
    function ClientMortgagesService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ClientMortgagesService.prototype.properties = function (client, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'reports/client-mortgages/properties/' + client + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ClientMortgagesService::properties, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    ClientMortgagesService.prototype.send = function (client, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'reports/client-mortgages/send/' + client + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ClientMortgagesService::send, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    ClientMortgagesService.prototype.getUrl = function (client, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'reports/client-mortgages/get-url/' + client + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ClientMortgagesService::getUrl, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    ClientMortgagesService.prototype.getDownloadUrl = function (client, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'reports/client-mortgages/get-download-url/' + client + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ClientMortgagesService::getDownloadUrl, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    return ClientMortgagesService;
}(ApiService));
export { ClientMortgagesService };

<div mat-dialog-title class="header">
  <span>Unqualified Chain Link</span>
  <!-- <button class="icon-close" mat-button mat-dialog-close>
    <img src="../../../assets/img/exit-icon.svg" alt="" >
  </button> -->
  <button mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="close()">
    <mat-icon class="close-icon" color="">close</mat-icon>
  </button>
</div>
<div mat-dialog-content class="content">
  This is an Unqualified Chain Link which ViewMyChain has added.
  <br>
  Please click Verify to confirm the link or Remove to break the chain.</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false" class="button is-primary" (click)="verify()">Verify</button>
  <button mat-button [mat-dialog-close]="false" class="button btn-remove" (click)="remove()">Remove</button>
</div>

import * as tslib_1 from "tslib";
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from './api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../shared/error-handler/error-handler.service";
var OutstandingItemService = /** @class */ (function (_super) {
    tslib_1.__extends(OutstandingItemService, _super);
    function OutstandingItemService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OutstandingItemService.prototype.get = function (id) {
        var url = this.url + 'listing/' + id + '/outstanding-items';
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('OutstandingItemService::get, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    OutstandingItemService.ngInjectableDef = i0.defineInjectable({ factory: function OutstandingItemService_Factory() { return new OutstandingItemService(i0.inject(i1.HttpClient), i0.inject(i2.ErrorHandlerService)); }, token: OutstandingItemService, providedIn: "root" });
    return OutstandingItemService;
}(ApiService));
export { OutstandingItemService };

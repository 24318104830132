import { Injector } from '@angular/core';
import Echo from 'laravel-echo';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../auth/authentication.service';
import * as i0 from "@angular/core";
var EchoService = /** @class */ (function () {
    function EchoService(injector) {
        this.injector = injector;
    }
    EchoService.prototype.init = function () {
        ;
        window.Pusher = require('pusher-js');
        var authService = this.injector.get(AuthenticationService);
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: environment.pusher_app_key,
            cluster: environment.pusher_app_cluster,
            encrypted: environment.pusher_app_encrypted,
            authEndpoint: environment.vmc_root_url + 'broadcasting/auth',
            auth: {
                headers: {
                    Authorization: 'Bearer ' + authService.accessToken(),
                    Accept: 'application/json',
                },
            },
        });
    };
    EchoService.ngInjectableDef = i0.defineInjectable({ factory: function EchoService_Factory() { return new EchoService(i0.inject(i0.INJECTOR)); }, token: EchoService, providedIn: "root" });
    return EchoService;
}());
export { EchoService };

import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, retryWhen } from 'rxjs/operators';
var ConveyancerService = /** @class */ (function (_super) {
    tslib_1.__extends(ConveyancerService, _super);
    function ConveyancerService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ConveyancerService.prototype.all = function (options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'conveyancer' + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ConveyancerService::all, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    ConveyancerService.prototype.filter = function (filters, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'conveyancer/filter' + ApiService.httpBuildQuery(options);
        return this.http.post(url, filters, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ConveyancerService::filter, url=' + url + ', filters=' + JSON.stringify(filters), {}, {
            url: url,
            method: 'POST',
            headers: this.getHeadersData(),
            data: filters,
        })));
    };
    ConveyancerService.prototype.get = function (id) {
        var url = this.url + 'conveyancer/' + id;
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ConveyancerService::get, url=' + url, {})));
    };
    return ConveyancerService;
}(ApiService));
export { ConveyancerService };

import * as tslib_1 from "tslib";
import { Base } from './base';
import { Page } from './page';
var Cta = /** @class */ (function (_super) {
    tslib_1.__extends(Cta, _super);
    function Cta(data) {
        if (data === void 0) { data = null; }
        var _this = _super.call(this, data) || this;
        _this.ctaEntryTitle = '';
        _this.ctaType = '';
        _this.ctaText = '';
        _this.ctaPage = new Page();
        _this.ctaUrl = '';
        _this.ctaClass = '';
        _this.ctaEntryTitle = _this.propertyIsValid(data, 'ctaEntryTitle') ? data.ctaEntryTitle : '';
        _this.ctaType = _this.propertyIsValid(data, 'ctaType') ? data.ctaType : '';
        _this.ctaText = _this.propertyIsValid(data, 'ctaText') ? data.ctaText : '';
        _this.ctaPage = _this.propertyIsValid(data, 'ctaPage', 'object') ? new Page(data.ctaPage) : new Page();
        _this.ctaUrl = _this.propertyIsValid(data, 'ctaUrl') ? data.ctaUrl : '';
        _this.ctaClass = _this.propertyIsValid(data, 'ctaClass') ? data.ctaClass : '';
        return _this;
    }
    return Cta;
}(Base));
export { Cta };

import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, retryWhen } from 'rxjs/operators';
var PrivateSearchesService = /** @class */ (function (_super) {
    tslib_1.__extends(PrivateSearchesService, _super);
    function PrivateSearchesService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PrivateSearchesService.prototype.difference = function (listingId) {
        var url = this.url + 'listing/' + listingId + '/private-searches';
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('PrivateSearchesService::difference, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    return PrivateSearchesService;
}(ApiService));
export { PrivateSearchesService };

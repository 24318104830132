import * as tslib_1 from "tslib";
import { Key } from './key';
import { Category } from './category';
import { Base, Collection } from './base';
var CaseStudy = /** @class */ (function (_super) {
    tslib_1.__extends(CaseStudy, _super);
    function CaseStudy(data) {
        if (data === void 0) { data = null; }
        var _this = _super.call(this, data) || this;
        _this.caseStudyTitle = '';
        _this.slug = '';
        _this.caseStudyImage = '';
        _this.caseStudyExcerpt = '';
        _this.caseStudyKeys = [];
        _this.caseStudyBody = '';
        _this.caseStudyFile = '';
        _this.category = new Category();
        _this.modelType = '';
        _this.sectionType = '';
        _this.caseStudyTitle = _this.propertyIsValid(data, 'caseStudyTitle') ? data.caseStudyTitle : '';
        _this.slug = _this.propertyIsValid(data, 'slug') ? data.slug : '';
        _this.caseStudyImage = _this.propertyIsValid(data, 'caseStudyImage') ? data.caseStudyImage : '';
        _this.caseStudyExcerpt = _this.propertyIsValid(data, 'caseStudyExcerpt') ? data.caseStudyExcerpt : '';
        _this.caseStudyBody = _this.propertyIsValid(data, 'caseStudyBody') ? data.caseStudyBody : '';
        _this.caseStudyFile = _this.propertyIsValid(data, 'caseStudyFile') ? data.caseStudyFile : '';
        _this.category = _this.propertyIsValid(data, 'category', 'object') ? new Category(data.category) : new Category();
        _this.modelType = _this.propertyIsValid(data, 'modelType') ? data.modelType : '';
        _this.sectionType = _this.propertyIsValid(data, 'sectionType') ? data.sectionType : '';
        _this.caseStudyKeys = _this.dataHasKey(data, 'caseStudyKeys') ? data['caseStudyKeys'].map(function (item) { return new Key(item); }) : [];
        return _this;
    }
    return CaseStudy;
}(Base));
export { CaseStudy };
var CaseStudyCollection = /** @class */ (function (_super) {
    tslib_1.__extends(CaseStudyCollection, _super);
    function CaseStudyCollection(data) {
        if (data === void 0) { data = null; }
        var _this = _super.call(this, data) || this;
        _this.data = [];
        _this.data = _this.dataHasKey(data, 'data') ? data['data'].map(function (item) { return new CaseStudy(item); }) : [];
        return _this;
    }
    return CaseStudyCollection;
}(Collection));
export { CaseStudyCollection };

import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, retryWhen } from 'rxjs/operators';
var UserService = /** @class */ (function (_super) {
    tslib_1.__extends(UserService, _super);
    function UserService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UserService.prototype.get = function (id, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + "user/" + id + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('UserService::get, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    UserService.prototype.contact = function (id, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + "user/" + id + "/relationships/contact" + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('UserService::contact, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    UserService.prototype.current = function (options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'user/current' + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders(), withCredentials: true }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('UserService::current, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    UserService.prototype.updateCurrent = function (attributes, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'user/current' + ApiService.httpBuildQuery(options);
        return this.http.put(url, attributes, { headers: this.getHeaders() }).pipe(catchError(this.handleError('UserService::updateCurrent, url=' + url, {})));
    };
    UserService.prototype.getInvites = function () {
        var url = this.url + 'settings/invitations/pending?includes=team';
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('UserService::getInvites, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    UserService.prototype.branches = function (options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'user/branches' + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('UserService::branches, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    UserService.prototype.paginateByType = function (name, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'users/type/' + name + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('UserService::paginateByType, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    UserService.prototype.maintenance = function () {
        var url = this.url + 'maintenance';
        return this.http.get(url);
    };
    return UserService;
}(ApiService));
export { UserService };

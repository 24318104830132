import * as tslib_1 from "tslib";
import { catchError, debounceTime, distinctUntilChanged, retryWhen } from 'rxjs/operators';
import { ApiService } from './api.service';
var LaggingListingService = /** @class */ (function (_super) {
    tslib_1.__extends(LaggingListingService, _super);
    function LaggingListingService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LaggingListingService.prototype.filter = function (filters) {
        var url = this.url + "lagging-listing/filter";
        return this.http.post(url, filters, { headers: this.getHeaders() }).pipe(debounceTime(300), distinctUntilChanged(), retryWhen(this.retryOnSystemFailure), catchError(this.handleError('LaggingListingService::filter, url=' + url + ', filters=' + JSON.stringify(filters), {}, {
            url: url,
            method: 'POST',
            headers: this.getHeadersData(),
            data: filters,
        })));
    };
    return LaggingListingService;
}(ApiService));
export { LaggingListingService };

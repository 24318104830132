import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, debounceTime, distinctUntilChanged, retryWhen } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../shared/error-handler/error-handler.service";
var ClientUserService = /** @class */ (function (_super) {
    tslib_1.__extends(ClientUserService, _super);
    function ClientUserService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ClientUserService.prototype.dashboardAverages = function () {
        var url = this.url + 'client-user/dashboard/averages';
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ClientUserService::clientUserDashboardAverages, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    ClientUserService.prototype.dashboardStatsByClient = function (options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'client-user/dashboard/clients' + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ClientUserService::clientUserDashboardStatsByClient, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    ClientUserService.prototype.crms = function () {
        var url = this.url + 'client-user/dashboard/crms';
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ClientUserService::crms, url=' + url, { data: [] }, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    ClientUserService.prototype.all = function (options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'client-user' + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ClientUserCollection::all, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    ClientUserService.prototype.get = function (id, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'client-user/' + id + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ClientUserCollection::get, url=' + url)));
    };
    ClientUserService.prototype.update = function (id, data) {
        var url = this.url + 'client-user/' + id;
        return this.http.put(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ClientUserService::update, url=' + url, {})));
    };
    ClientUserService.prototype.create = function (data) {
        var url = this.url + 'client-user';
        return this.http.post(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ClientUserService::create, url=' + url, {})));
    };
    ClientUserService.prototype.delete = function (id) {
        var url = this.url + 'client-user/' + id;
        return this.http.delete(url, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ClientUserService::delete, url=' + url + ', team_id=' + id, [])));
    };
    ClientUserService.prototype.filter = function (filters, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + "client-user/filter" + ApiService.httpBuildQuery(options);
        return this.http.post(url, filters, { headers: this.getHeaders() }).pipe(debounceTime(300), distinctUntilChanged(), retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ClientUserService::filter, url=' + url + ', filters=' + JSON.stringify(filters), {}, {
            url: url,
            method: 'POST',
            headers: this.getHeadersData(),
            data: filters,
        })));
    };
    ClientUserService.ngInjectableDef = i0.defineInjectable({ factory: function ClientUserService_Factory() { return new ClientUserService(i0.inject(i1.HttpClient), i0.inject(i2.ErrorHandlerService)); }, token: ClientUserService, providedIn: "root" });
    return ClientUserService;
}(ApiService));
export { ClientUserService };

import * as tslib_1 from "tslib";
import { ApiService } from '../api-services/api.service';
import { catchError, mergeMap, retryWhen } from 'rxjs/operators';
import { Page } from '../models/content/page';
import { CaseStudyCollection } from '../models/content/case-study';
import { NewsCollection } from '../models/content/news';
import { timer } from 'rxjs/internal/observable/timer';
import { range } from 'rxjs/internal/observable/range';
import { zip } from 'rxjs/internal/observable/zip';
import { throwError } from 'rxjs/internal/observable/throwError';
var ContentService = /** @class */ (function (_super) {
    tslib_1.__extends(ContentService, _super);
    function ContentService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ContentService.prototype.get = function (id) {
        var url = this.url + 'content/id/' + id;
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ContentService::get, url=' + url + ', id=' + id, [])));
    };
    ContentService.prototype.getPage = function (slug) {
        var url = this.url + 'content/page/' + slug;
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ContentService::getPage, url=' + url, new Page())));
    };
    ContentService.prototype.getPost = function (category, slug) {
        var url = this.url + 'content/post/category/' + category + '/slug/' + slug;
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ContentService::getPost, url=' + url, { sectionType: '' })));
    };
    ContentService.prototype.getNews = function (category, params) {
        if (params === void 0) { params = null; }
        var url = this.url + 'content/post/type/' + category;
        return this.http.get(url, { headers: this.getHeaders(), params: params }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ContentService::getNews, url=' + url, new NewsCollection())));
    };
    ContentService.prototype.getPosts = function (category, params) {
        if (params === void 0) { params = null; }
        var url = this.url + 'content/post/type/' + category;
        return this.http.get(url, { headers: this.getHeaders(), params: params }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ContentService::getPosts, url=' + url, { data: [] })));
    };
    ContentService.prototype.getCaseStudies = function (category, params) {
        if (params === void 0) { params = null; }
        var url = this.url + 'content/post/type/' + category;
        return this.http.get(url, { headers: this.getHeaders(), params: params }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ContentService::getCaseStudies, url=' + url, new CaseStudyCollection())));
    };
    ContentService.prototype.sendContact = function (contact) {
        var url = this.url + 'mail/contact';
        return this.http.post(url, contact, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ContentService::sendContact, url=' + url + ', contact=' + JSON.stringify(contact), [])));
    };
    ContentService.prototype.retryOnSystemFailure = function (errors) {
        return zip(errors, range(1, 4)).pipe(mergeMap(function (_a) {
            var error = _a[0], i = _a[1];
            return (i < 4 ? timer(300) : throwError(error));
        }));
    };
    ContentService.prototype.encrypt = function (toEncrypt) {
        var url = this.url + 'partner/encrypt';
        return this.http.post(url, toEncrypt, { headers: this.headers }).pipe(catchError(this.handleError('ContentService::testCon38Url, url=' + url + ', data=' + toEncrypt, [])));
    };
    ContentService.prototype.testCon38Url = function (client_id, data) {
        var url = this.url + 'partner/' + client_id;
        return this.http.post(url, { data: data }, { headers: this.headers, withCredentials: true }).pipe(catchError(this.handleError('ContentService::testCon38Url, url=' + url + ', data=' + data, [])));
    };
    return ContentService;
}(ApiService));
export { ContentService };

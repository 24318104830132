<!-- <mat-progress-bar *ngIf="running" mode="indeterminate"></mat-progress-bar> -->
<section class="section">
  <!-- <div class="container">
    <div class="columns">
      <div class="column is-half">
        <div class="box content">
          <h3>Login</h3>
          <article class="message" *ngIf="message.length > 0"
                   [ngClass]="{ 'is-success': success, 'is-danger': !success }">
            <div class="message-body">{{ message }}</div>
          </article>
          <form (ngSubmit)="onSubmit(email.value, password.value)" #loginForm="ngForm" autocomplete="off">
            <mat-form-field>
              <input matInput placeholder="Email" name="email" required autofocus #email type="email"
                     id="login-email">
            </mat-form-field>

            <mat-form-field>
              <input matInput name="password" placeholder="Password" required #password type="password"
                     id="login-password">
            </mat-form-field>

            <button class="button login-button is-primary is-medium" [ngClass]="{'is-loading': running}" id="login-login">
              <div class="full-width">Login</div>
            </button>
            <a routerLink="/password/forgot" class="has-text-right" id="login-forgot-password">Forgotten your password?</a>
            <a routerLink="/register" class="has-text-right" id="login-register">Register?</a>
          </form>
        </div>
      </div>
    </div>
  </div> -->
</section>

import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError } from 'rxjs/operators';
var ListingNotificationService = /** @class */ (function (_super) {
    tslib_1.__extends(ListingNotificationService, _super);
    function ListingNotificationService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ListingNotificationService.prototype.get = function (id) {
        var url = this.url + 'listing/' + id + '/notifications';
        return this.http.get(url, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ListingNotificationService::get, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    return ListingNotificationService;
}(ApiService));
export { ListingNotificationService };

import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, retryWhen } from 'rxjs/operators';
var ClientSearchesService = /** @class */ (function (_super) {
    tslib_1.__extends(ClientSearchesService, _super);
    function ClientSearchesService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ClientSearchesService.prototype.properties = function (client, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'reports/client-searches/properties/' + client + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ClientSearchesService::properties, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    ClientSearchesService.prototype.getUrl = function (client, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'reports/client-searches/get-url/' + client + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ClientSearchesService::getUrl, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    ClientSearchesService.prototype.getDownloadUrl = function (client, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'reports/client-searches/get-download-url/' + client + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ClientSearchesService::getDownloadUrl, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    ClientSearchesService.prototype.send = function (client, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'reports/client-searches/send/' + client + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ClientSearchesService::send, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    return ClientSearchesService;
}(ApiService));
export { ClientSearchesService };

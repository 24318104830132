import * as tslib_1 from "tslib";
import { ApiService } from "./api.service";
import { catchError } from "rxjs/operators";
var ListingChainService = /** @class */ (function (_super) {
    tslib_1.__extends(ListingChainService, _super);
    function ListingChainService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ListingChainService.prototype.checkTop = function (listing) {
        var url = this.url + "listing/chain/" + listing.id + "/check/top";
        return this.http
            .put(url, {}, { headers: this.getHeaders() })
            .pipe(catchError(this.handleError("ListingChainService::checkTop, url=" + url, {})));
    };
    ListingChainService.prototype.uncheckTop = function (listing) {
        var url = this.url + "listing/chain/" + listing.id + "/uncheck/top";
        return this.http
            .put(url, {}, { headers: this.getHeaders() })
            .pipe(catchError(this.handleError("ListingChainService::uncheckTop, url=" + url, {})));
    };
    ListingChainService.prototype.checkBottom = function (listing) {
        var url = this.url + "listing/chain/" + listing.id + "/check/bottom";
        return this.http
            .put(url, {}, { headers: this.getHeaders() })
            .pipe(catchError(this.handleError("ListingChainService::checkBottom, url=" + url, {})));
    };
    ListingChainService.prototype.uncheckBottom = function (listing) {
        var url = this.url + "listing/chain/" + listing.id + "/uncheck/bottom";
        return this.http
            .put(url, {}, { headers: this.getHeaders() })
            .pipe(catchError(this.handleError("ListingChainService::uncheckBottom, url=" + url, {})));
    };
    ListingChainService.prototype.addListingToChain = function (listing, data) {
        var url = this.url + "listing/chain/" + listing.id + "/add";
        return this.http
            .post(url, data, { headers: this.getHeaders() })
            .pipe(catchError(this.handleError("ListingChainService::addListingToChain, url=" + url, {})));
    };
    ListingChainService.prototype.addTop = function (listing, link) {
        var url = this.url + "listing/chain/" + listing.id + "/add/top/" + link.id;
        return this.http
            .put(url, {}, { headers: this.getHeaders() })
            .pipe(catchError(this.handleError("ListingChainService::addTop, url=" + url, {})));
    };
    ListingChainService.prototype.addBottom = function (listing, link) {
        var url = this.url + "listing/chain/" + listing.id + "/add/bottom/" + link.id;
        return this.http
            .put(url, {}, { headers: this.getHeaders() })
            .pipe(catchError(this.handleError("ListingChainService::addBottom, url=" + url, {})));
    };
    ListingChainService.prototype.chainMap = function (listing) {
        var url = this.url + "listing/chain/" + listing.id + "/chain-map/";
        return this.http
            .get(url, { headers: this.getHeaders() })
            .pipe(catchError(this.handleError("ListingChainService::chainMap, url=" + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData()
        })));
    };
    return ListingChainService;
}(ApiService));
export { ListingChainService };

import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError } from 'rxjs/operators';
var ListingMetaService = /** @class */ (function (_super) {
    tslib_1.__extends(ListingMetaService, _super);
    function ListingMetaService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ListingMetaService.prototype.create = function (data) {
        var url = this.url + 'listing_meta';
        return this.http.post(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ListingMetaService::create, url=' + url, {})));
    };
    ListingMetaService.prototype.update = function (id, data) {
        var url = this.url + 'listing_meta/' + id;
        return this.http.put(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ListingMetaService::update, url=' + url, {})));
    };
    ListingMetaService.prototype.sites = function (filters) {
        if (filters === void 0) { filters = {}; }
        var url = this.url + 'listing_meta/sites' + ApiService.httpBuildQuery(filters);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ListingMetaService::sites, url=' + url + ' filters=' + JSON.stringify(filters), {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    ListingMetaService.prototype.plots = function (filters) {
        if (filters === void 0) { filters = {}; }
        var url = this.url + 'listing_meta/plots' + ApiService.httpBuildQuery(filters);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ListingMetaService::plots, url=' + url + ' filters=' + JSON.stringify(filters), {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    return ListingMetaService;
}(ApiService));
export { ListingMetaService };

<vmc-header-default *ngIf="!loggedIn()"
                    [content]="content"
                    [home_link]="home_link"
                    [isMenuOpened]="isMenuOpened"
                    (onMenuToggle)="toggleMenu()"
>
</vmc-header-default>

<ng-template [ngIf]="loggedIn()">
  <vmc-header-agent *ngIf="!isConsumer"
                    [canManageBranches]="canManageBranches"
                    [content]="content"
                    [currentUser]="currentUser"
                    [home_link]="home_link"
                    [isMenuOpened]="isMenuOpened"
                    [showBrochure]="showBrochure"
                    (onContactUs)="showContact()"
                    (onMenuToggle)="toggleMenu()"
                    [canManageClients]="canManageClients"
                    [canManageClientContacts]="canManageClientContacts"
                    [canViewDashboard]="canViewDashboard"
                    [canViewReportsPage]="canViewReportsPage"
                    [isClientUserHome]="isClientUserHome"
                    [canManageClientUsers]="canManageClientUsers"
                    [isCompanyConveyancer]="isCompanyConveyancer"
                    [isIndividualConveyancer]="isIndividualConveyancer"
                    [isIndividualConsumer]="isIndividualConsumer"
  >
  </vmc-header-agent>
  <vmc-header-consumer *ngIf="isConsumer"
                       [content]="content"
                       [currentUser]="currentUser"
                       [isMenuOpened]="isMenuOpened"
                       [showBrochure]="showBrochure"
                       (onContactUs)="showContact()"
                       (onMenuToggle)="toggleMenu()"
  >
  </vmc-header-consumer>
</ng-template>

import { Injector } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { EchoService } from '../shared/echo.service';
import { Location } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "./authentication.service";
import * as i2 from "../shared/echo.service";
var RefreshService = /** @class */ (function () {
    function RefreshService(auth, echo, injector) {
        this.auth = auth;
        this.echo = echo;
        this.injector = injector;
        this.refreshTokenInProgress = false;
        this.refreshTokenHasFailed = false;
        this.tokenRefreshedSource = new Subject();
        this.tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
    }
    RefreshService.prototype.handle = function () {
        var _this = this;
        if (this.refreshTokenInProgress) {
            return new Observable(function (observer) {
                _this.tokenRefreshed$.subscribe(function () {
                    observer.next();
                    observer.complete();
                });
            });
        }
        else {
            this.refreshTokenInProgress = true;
            return this.auth.refreshToken().pipe(tap(function (response) {
                _this.refreshTokenInProgress = false;
                _this.tokenRefreshedSource.next();
                _this.echo.init();
            }));
        }
    };
    RefreshService.prototype.error = function (error) {
        this.refreshTokenHasFailed = true;
        this.auth.redirectUrl = this.injector.get(Location).path();
        this.auth.logout();
        return throwError(error);
    };
    RefreshService.ngInjectableDef = i0.defineInjectable({ factory: function RefreshService_Factory() { return new RefreshService(i0.inject(i1.AuthenticationService), i0.inject(i2.EchoService), i0.inject(i0.INJECTOR)); }, token: RefreshService, providedIn: "root" });
    return RefreshService;
}());
export { RefreshService };

import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, retryWhen } from 'rxjs/operators';
var ClientChainService = /** @class */ (function (_super) {
    tslib_1.__extends(ClientChainService, _super);
    function ClientChainService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ClientChainService.prototype.properties = function (client) {
        var url = this.url + 'reports/client-chain/properties/' + client;
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ClientChainService::properties, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    ClientChainService.prototype.propertyDetails = function (id) {
        var url = this.url + 'reports/client-chain/properties/' + id + '/breakdown';
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ClientChainService::propertyDetails, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    ClientChainService.prototype.getUrl = function (client) {
        var url = this.url + 'reports/client-chain/get-url/' + client;
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ClientChainService::getUrl, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    ClientChainService.prototype.getDownloadUrl = function (client) {
        var url = this.url + 'reports/client-chain/get-download-url/' + client;
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ClientChainService::getDownloadUrl, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    ClientChainService.prototype.send = function (client) {
        var url = this.url + 'reports/client-chain/send/' + client;
        return this.http.get(url, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ClientChainService::send, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    return ClientChainService;
}(ApiService));
export { ClientChainService };

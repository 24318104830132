import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, retryWhen } from 'rxjs/operators';
var PossibleLinksService = /** @class */ (function (_super) {
    tslib_1.__extends(PossibleLinksService, _super);
    function PossibleLinksService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PossibleLinksService.prototype.top = function (id) {
        var url = this.url + 'listing/' + id + '/possible-links/top';
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('PossibleLinksService::top, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    PossibleLinksService.prototype.dismissTop = function (id) {
        var url = this.url + 'listing/' + id + '/possible-links/dismiss-top';
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('PossibleLinksService::dismissTop, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    PossibleLinksService.prototype.bottom = function (id) {
        var url = this.url + 'listing/' + id + '/possible-links/bottom';
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('PossibleLinksService::bottom, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    return PossibleLinksService;
}(ApiService));
export { PossibleLinksService };

import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError } from 'rxjs/operators';
var ProspectiveChainService = /** @class */ (function (_super) {
    tslib_1.__extends(ProspectiveChainService, _super);
    function ProspectiveChainService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProspectiveChainService.prototype.add = function (data) {
        return this.http.post(this.url + 'prospective-chain', data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ProspectiveChainService::add, url=' + this.url + 'prospective-chain, data=' + JSON.stringify(data), {})));
    };
    return ProspectiveChainService;
}(ApiService));
export { ProspectiveChainService };

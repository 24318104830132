import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, retryWhen } from 'rxjs/operators';
var ContactService = /** @class */ (function (_super) {
    tslib_1.__extends(ContactService, _super);
    function ContactService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ContactService.prototype.attachToListing = function (listing, contact, role) {
        var url = this.url + "contact/" + contact + '/listing/' + listing + '/attach';
        return this.http.post(url, { role: role }, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ContactService::attachToListing, url=' + url + ', role=' + role, {})));
    };
    ContactService.prototype.create = function (data) {
        var url = this.url + 'contact';
        return this.http.post(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ContactService::create, url=' + url, {})));
    };
    ContactService.prototype.update = function (id, data) {
        var url = this.url + 'contact/' + id;
        return this.http.put(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ContactService::update, url=' + url + ', id=' + id, {})));
    };
    ContactService.prototype.get = function (id) {
        var url = this.url + 'contact/' + id;
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ContactService::get, url=' + url + ', id=' + id, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    ContactService.prototype.reports = function (id) {
        var url = this.url + 'contact/' + id + '/relationships/reports';
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ContactService::reports, url=' + url + ', id=' + id, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    ContactService.prototype.getIndividualByBranch = function (branchId) {
        var url = this.url + 'individual/get-by-branch/' + branchId;
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ContactService::reports, url=' + url + ', id=' + branchId, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    ContactService.prototype.getIndividualAddress = function (individualId) {
        var url = this.url + 'individual/' + individualId + '/individual-address';
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ContactService::reports, url=' + url + ', id=' + individualId, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    ContactService.prototype.createIndividualAddress = function (data) {
        var url = this.url + 'individual/' + data.individual_id + '/individual-address';
        return this.http.post(url, data, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ContactService::individualAddress, url=' + url + ', id=' + data.individual_id)));
    };
    ContactService.prototype.getIndividual = function (id) {
        var url = this.url + 'individual/' + id;
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ContactService::individual, url=' + url + ', id=' + id, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    return ContactService;
}(ApiService));
export { ContactService };

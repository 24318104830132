/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cta.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../../pipes/safe.pipe";
import * as i5 from "@angular/platform-browser";
import * as i6 from "./cta.component";
var styles_CtaComponent = [i0.styles];
var RenderType_CtaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CtaComponent, data: {} });
export { RenderType_CtaComponent as RenderType_CtaComponent };
function View_CtaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["id", "cta-external-link"]], [[8, "href", 4], [1, "target", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpad(2, 2), (_l()(), i1.ɵted(3, null, [" ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 2, 0, "button", (_co.cta.ctaClass ? _co.cta.ctaClass : "")); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.cta.ctaUrl, ""); var currVal_1 = (_co.isExternalLink() ? "_blank" : ""); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.cta.ctaText; _ck(_v, 3, 0, currVal_3); }); }
function View_CtaComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["id", "cta-internal-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpad(2, 2), i1.ɵdid(3, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(4, null, [" ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 2, 0, "button", (_co.cta.ctaClass ? _co.cta.ctaClass : "")); _ck(_v, 1, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.cta.ctaUrl, ""); _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _co.cta.ctaText; _ck(_v, 4, 0, currVal_4); }); }
function View_CtaComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["id", "cta-tel-link"]], [[8, "href", 4]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpad(2, 2), (_l()(), i1.ɵted(3, null, [" ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 2, 0, "cta-tel", (_co.cta.ctaClass ? _co.cta.ctaClass : "")); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "tel:", _co.cta.ctaUrl, ""); _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.cta.ctaText; _ck(_v, 3, 0, currVal_2); }); }
function View_CtaComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["href", "javascript:void(0)"], ["id", "cta-video-trigger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpad(2, 2), (_l()(), i1.ɵted(3, null, [" ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, "button", (_co.cta.ctaClass ? _co.cta.ctaClass : "")); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.cta.ctaText; _ck(_v, 3, 0, currVal_1); }); }
function View_CtaComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "modal video-modal": 0, "is-active": 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "modal-background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "iframe", [["frameborder", "0"], ["height", "400"], ["width", "100%"]], [[8, "src", 5]], null, null, null, null)), i1.ɵppd(6, 2), (_l()(), i1.ɵeld(7, 0, null, null, 0, "button", [["class", "modal-close"], ["id", "cta-close-modal"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, true, _co.activeModal); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), _co.cta.ctaUrl, "url")); _ck(_v, 5, 0, currVal_1); }); }
export function View_CtaComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.SafePipe, [i5.DomSanitizer]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CtaComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CtaComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CtaComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CtaComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CtaComponent_5)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isExternalLink(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isInternalLink(); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.isTelLink(); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.isVideoTrigger(); _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.isVideoTrigger(); _ck(_v, 10, 0, currVal_4); }, null); }
export function View_CtaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vmc-cta", [], null, null, null, View_CtaComponent_0, RenderType_CtaComponent)), i1.ɵdid(1, 49152, null, 0, i6.CtaComponent, [], null, null)], null, null); }
var CtaComponentNgFactory = i1.ɵccf("vmc-cta", i6.CtaComponent, View_CtaComponent_Host_0, { cta: "cta" }, {}, []);
export { CtaComponentNgFactory as CtaComponentNgFactory };

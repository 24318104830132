<a *ngIf="isExternalLink()" [ngClass]="['button', cta.ctaClass ? cta.ctaClass : '']" href="{{ cta.ctaUrl }}" [attr.target]="isExternalLink() ? '_blank' : ''"
   id="cta-external-link">
  {{ cta.ctaText }}
</a>

<a *ngIf="isInternalLink()" [ngClass]="['button', cta.ctaClass ? cta.ctaClass : '']" routerLink="{{ cta.ctaUrl }}" id="cta-internal-link">
  {{ cta.ctaText }}
</a>

<a *ngIf="isTelLink()" [ngClass]="['cta-tel', cta.ctaClass ? cta.ctaClass : '']" href="tel:{{ cta.ctaUrl }}" id="cta-tel-link">
  {{ cta.ctaText }}
</a>

<a *ngIf="isVideoTrigger()" href="javascript:void(0)" [ngClass]="['button', cta.ctaClass ? cta.ctaClass : '']" (click)="toggleModal()" id="cta-video-trigger">
  {{ cta.ctaText }}
</a>

<div *ngIf="isVideoTrigger()" [ngClass]="{'modal video-modal': true, 'is-active': activeModal}">
  <div class="modal-background"></div>
  <div class="modal-content">
    <iframe [src]="cta.ctaUrl | safe: 'url'" frameborder="0" width="100%" height="400"></iframe>
  </div>
  <button class="modal-close" (click)="toggleModal()" id="cta-close-modal"></button>
</div>

<header class="header" *ngIf="content" [ngClass]="{'is-active-canvas': isMenuOpened}">

  <!--Desktop-->
  <div class="container">
    <nav class="navbar is-hidden-touch" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item" routerLink="{{ home_link }}" id="header-desktop-home">
          <img src="{{ content.headerLogo }}" alt="View My Chain Logo">
        </a>
      </div>
      <div class="navbar-menu">
        <!-- navbar start, navbar end -->
        <div class="navbar-start">
          <!-- navbar items -->
          <a class="navbar-item" routerLink="/home-mover" routerLinkActive="is-active" id="header-desktop-home-mover">
            Your Active Chain
          </a>
        </div>

        <div class="navbar-end">
          <!-- navbar items -->

          <ng-template [ngIf]="showBrochure">
            <ng-container *ngFor="let item of content.headerMainMenu.menuItems">
              <a *ngIf="item.isExternal" href="{{ item.menuUrl }}" target="_blank" rel="noopener" class="navbar-item" id="header-desktop-menu-link-external-{{ item.menuName }}">
                {{ item.menuName }}
              </a>
              <a *ngIf="!item.isExternal" routerLink="{{ item.menuUrl }}" class="navbar-item" routerLinkActive="is-active"
                 id="header-desktop-menu-link-internal-{{ item.menuName }}">
                {{ item.menuName }}
              </a>
            </ng-container>
          </ng-template>

          <!--Phone Number-->
          <a class="navbar-item is-contact-link" href="tel:{{ content.headerTopCta.ctaUrl }}" id="header-desktop-tel">
            <span class="icon"><i class="fa fa-phone"></i></span>
            <span class="text">{{ content.headerTopCta.ctaText }}</span>
          </a>

          <!--Launch Intercom-->
<!--          <a class="navbar-item is-contact-link" (click)="showContact()" id="header-desktop-launch-intercom">-->
<!--            <span class="icon"><i class="fa fa-question-circle"></i> </span>-->
<!--            <span class="text">Help</span>-->
<!--          </a>-->

          <!--Drop Down-->
          <a class="navbar-item has-dropdown" [ngClass]="{ 'is-active': openUserMenu}" (click)="toggleUserMenu()" (blur)="openUserMenu = false"
             id="header-desktop-toggle-user-menu">
            <div class="navbar-link">
              <span class="icon"><i class="fa fa-user"></i> </span>
              <span>{{currentUser.attributes.first_name}}</span>
            </div>

            <div class="navbar-dropdown is-right">
              <!-- Other navbar items -->
              <a class="navbar-item" routerLink="/home-mover/profile" routerLinkActive="is-active" (click)="toggleUserMenu()" id="header-desktop-home-mover-profile">
                Profile
              </a>
              <hr class="navbar-divider">
              <a class="navbar-item" (click)="logout()" id="header-desktop-logout">
                Logout
              </a>
            </div>
          </a>
        </div>
      </div>
    </nav>
  </div>

  <!--Mobile-->
  <div class="header-mobile is-hidden-desktop">
    <nav class="container nav-mobile navbar">
      <div class="navbar-brand">
        <a class='navbar-item' routerLink="{{ home_link }}" id="header-mobile-home">
          <img src="{{ content.headerLogo }}" alt="View My Chain Logo">
        </a>
        <div class="navbar-burger" (click)="toggleMenu()" [ngClass]="{'is-active': isMenuOpened}" id="header-mobile-hamburger">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </nav>
    <aside class="menu menu-canvas" [ngClass]="{'is-active': isMenuOpened}" style="z-index: 1000;">
      <div class="nav-primary">
        <div class="navbar-actions">
          <ul>
            <li>
              <a class="button is-primary is-inverted has-text-left"
                 routerLink="/home-mover"
                 routerLinkActive="is-active"
                 [routerLinkActiveOptions]="{exact:true}"
                 id="header-mobile-your-active-chain"
              >
                Your Active Chain
              </a>
            </li>
            <li>
              <a class="button is-primary is-inverted has-text-left"
                 routerLink="/home-mover/profile"
                 routerLinkActive="is-active"
                 [routerLinkActiveOptions]="{exact:true}"
                 id="header-mobile-home-mover-profile"
              >
                Profile
              </a>
            </li>
          </ul>
        </div>
        <hr>
        <vmc-menu [menu]="content.headerMainMenu" [itemClass]="'button is-white'" [itemType]="'header'"></vmc-menu>
      </div>
      <hr>
      <div class="navbar-actions">
        <ul>
          <li>
            <a class="button is-primary is-inverted has-text-left" href="tel:{{ content.headerTopCta.ctaUrl }}" id="header-mobile-tel">
              {{ content.headerTopCta.ctaText }}
            </a>
          </li>
<!--          <li>-->
<!--            <a class="button is-primary is-inverted has-text-left" (click)="showContact()" id="header-mobile-launch-intercom">-->
<!--              <span>Help</span>-->
<!--            </a>-->
<!--          </li>-->
          <li>
            <a class="button is-primary is-inverted has-text-left" (click)="logout()" id="header-mobile-logout">Logout</a>
          </li>
        </ul>

      </div>
    </aside>
  </div>
  <div class="overlay is-hidden is-hidden-tablet"></div>
</header>

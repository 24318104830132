/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./critical-content/header/header.component.ngfactory";
import * as i4 from "./critical-content/header/header.component";
import * as i5 from "./auth/authentication.service";
import * as i6 from "@angular/router";
import * as i7 from "./critical-content/is-content.service";
import * as i8 from "./critical-content/link.service";
import * as i9 from "./critical-content/footer/footer.component.ngfactory";
import * as i10 from "./critical-content/footer/footer.component";
import * as i11 from "./app.component";
import * as i12 from "./shared/echo.service";
import * as i13 from "./api-services/user.service";
import * as i14 from "@angular/service-worker";
import * as i15 from "@angular/material/snack-bar";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "wrapper"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "is-active-canvas": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "vmc-header", [], null, [[null, "openMenuEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("openMenuEmitter" === en)) {
        var pd_0 = (_co.handleHeaderMenu($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_HeaderComponent_0, i3.RenderType_HeaderComponent)), i1.ɵdid(4, 245760, null, 0, i4.HeaderComponent, [i5.AuthenticationService, i6.Router, i7.IsContentService, i8.LinkService], null, { openMenuEmitter: "openMenuEmitter" }), (_l()(), i1.ɵeld(5, 0, null, null, 2, "main", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "vmc-footer", [], null, null, null, i9.View_FooterComponent_0, i9.RenderType_FooterComponent)), i1.ɵdid(9, 114688, null, 0, i10.FooterComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "wrapper"; var currVal_1 = _ck(_v, 2, 0, _co.isHeaderCanvas); _ck(_v, 1, 0, currVal_0, currVal_1); _ck(_v, 4, 0); _ck(_v, 7, 0); _ck(_v, 9, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vmc-app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i11.AppComponent, [i6.Router, i12.EchoService, i2.Location, i5.AuthenticationService, i13.UserService, i14.SwUpdate, i15.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("vmc-app", i11.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };

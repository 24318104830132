/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-found.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../cta/cta.component.ngfactory";
import * as i3 from "../cta/cta.component";
import * as i4 from "@angular/common";
import * as i5 from "../../markdown/markdown.component.ngfactory";
import * as i6 from "../../markdown/markdown.component";
import * as i7 from "ng-lazyload-image";
import * as i8 from "./not-found.component";
import * as i9 from "../content.service";
import * as i10 from "../is-content.service";
var styles_NotFoundComponent = [i0.styles];
var RenderType_NotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
function View_NotFoundComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["NotFound"]))], null, null); }
function View_NotFoundComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vmc-cta", [], null, null, null, i2.View_CtaComponent_0, i2.RenderType_CtaComponent)), i1.ɵdid(1, 49152, null, 0, i3.CtaComponent, [], { cta: [0, "cta"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_NotFoundComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "cta__list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotFoundComponent_4)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content.heroCtAs; _ck(_v, 2, 0, currVal_0); }, null); }
function View_NotFoundComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "columns"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "column not-found__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "not-found__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p", [["class", "not-found__message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "vmc-markdown", [], null, null, null, i5.View_MarkdownComponent_0, i5.RenderType_MarkdownComponent)), i1.ɵdid(7, 573440, null, 0, i6.MarkdownComponent, [], { data: [0, "data"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotFoundComponent_3)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "figure", [["class", "column not-found__img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "img", [["alt", "not-found-image"]], null, null, null, null, null)), i1.ɵdid(12, 1720320, null, 0, i7.LazyLoadImageDirective, [i1.ElementRef, i1.NgZone, [2, "options"]], { lazyImage: [0, "lazyImage"], offset: [1, "offset"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.content.heroMessage; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.content.heroCtAs; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.content.heroImage; var currVal_4 = 100; _ck(_v, 12, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content.heroTitle; _ck(_v, 4, 0, currVal_0); }); }
export function View_NotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "section", [["class", "section section__not-found not-found"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotFoundComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotFoundComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.content; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.content; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_NotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vmc-not-found", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i1.ɵdid(1, 8503296, null, 0, i8.NotFoundComponent, [i9.ContentService, i10.IsContentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotFoundComponentNgFactory = i1.ɵccf("vmc-not-found", i8.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };

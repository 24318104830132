import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError } from 'rxjs/operators';
var InvitationService = /** @class */ (function (_super) {
    tslib_1.__extends(InvitationService, _super);
    function InvitationService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InvitationService.prototype.create = function (data) {
        var url = this.url + 'invitation';
        return this.http.post(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('InvitationService::create, url=' + url, {})));
    };
    return InvitationService;
}(ApiService));
export { InvitationService };

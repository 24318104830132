import * as tslib_1 from "tslib";
import { Cta } from './cta';
import { Base } from './base';
var Hero = /** @class */ (function (_super) {
    tslib_1.__extends(Hero, _super);
    function Hero(data) {
        if (data === void 0) { data = null; }
        var _this = _super.call(this, data) || this;
        _this.heroEntryTitle = '';
        _this.heroTitle = '';
        _this.heroMessage = '';
        _this.heroImage = '';
        _this.heroCtAs = [];
        _this.sectionType = '';
        _this.heroCtAs = _this.dataHasKey(data, 'heroCtAs') ? data['heroCtAs'].map(function (item) { return new Cta(item); }) : [];
        _this.heroEntryTitle = _this.propertyIsValid(data, 'heroEntryTitle') ? data.heroEntryTitle : '';
        _this.heroTitle = _this.propertyIsValid(data, 'heroTitle') ? data.heroTitle : '';
        _this.heroMessage = _this.propertyIsValid(data, 'heroMessage') ? data.heroMessage : '';
        _this.heroImage = _this.propertyIsValid(data, 'heroImage') ? data.heroImage : '';
        _this.sectionType = _this.propertyIsValid(data, 'sectionType') ? data.sectionType : '';
        return _this;
    }
    return Hero;
}(Base));
export { Hero };

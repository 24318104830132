import * as tslib_1 from "tslib";
import { catchError, debounceTime, distinctUntilChanged, retryWhen } from 'rxjs/operators';
import { ApiService } from './api.service';
var ListingService = /** @class */ (function (_super) {
    tslib_1.__extends(ListingService, _super);
    function ListingService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ListingService.prototype.filter = function (filters, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + "listing/filter" + ApiService.httpBuildQuery(options);
        return this.http.post(url, filters, { headers: this.getHeaders() }).pipe(debounceTime(300), distinctUntilChanged(), retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingService::filter, url=' + url + ', filters=' + JSON.stringify(filters), {}, {
            url: url,
            headers: this.getHeadersData(),
            data: filters,
            method: 'POST'
        })));
    };
    ListingService.prototype.exists = function (filters, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + "listing/exists" + ApiService.httpBuildQuery(options);
        return this.http.post(url, filters, { headers: this.getHeaders() }).pipe(debounceTime(300), distinctUntilChanged(), retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingService::exists, url=' + url + ',filters=' + JSON.stringify(filters), {}, {
            url: url,
            headers: this.getHeadersData(),
            data: filters,
            method: 'POST',
        })));
    };
    ListingService.prototype.get = function (id, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + "listing/" + id + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingService::get, url=' + url, {}, {
            url: url,
            headers: this.getHeadersData(),
            method: 'GET'
        })));
    };
    ListingService.prototype.getListingDetail = function (addressID, branchID) {
        var params = {
            address_id: addressID,
            branch_id: branchID
        };
        var headers = this.getHeaders();
        var url = this.url + "get-listing-detail" + ApiService.httpBuildQuery(params);
        return this.http.get(url, { headers: headers }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingService::getListingDetail, url=' + url, {}, {
            url: url,
            headers: this.getHeadersData(),
            method: 'GET'
        })));
    };
    ListingService.prototype.branch = function (id, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + "listing/" + id + "/relationships/branch" + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingService::branch, url=' + url, {}, {
            url: url,
            headers: this.getHeadersData(),
            method: 'GET'
        })));
    };
    ListingService.prototype.saleConveyancer = function (id, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + "listing/" + id + "/relationships/sale-conveyancer" + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingService::saleConveyancer, url=' + url, {}, {
            url: url,
            headers: this.getHeadersData(),
            method: 'GET'
        })));
    };
    ListingService.prototype.chainData = function (id, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + "listing/" + id + "/relationships/chain-data" + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingService::chainData, url=' + url, {}, {
            url: url,
            headers: this.getHeadersData(),
            method: 'GET'
        })));
    };
    ListingService.prototype.add = function (data) {
        return this.http.post(this.url + 'listing', data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ListingService::add, url=' + this.url + 'listing, data=' + JSON.stringify(data), {})));
    };
    ListingService.prototype.update = function (listing, attributes) {
        var url = this.url + "listing/" + listing.id;
        var data = this.prepListingForSubmission(listing, attributes);
        return this.http.put(url, JSON.stringify(data), { headers: this.getHeaders() }).pipe(catchError(this.handleError('ListingService::update, url=' + url + ',data=' + JSON.stringify(data), {})));
    };
    ListingService.prototype.updateMilestones = function (listing, data, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + "listing/" + listing.id + "/milestones" + ApiService.httpBuildQuery(options);
        return this.http.put(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ListingService::updateMilestones, url=' + url + ', data=' + JSON.stringify(data), {})));
    };
    ListingService.prototype.complete = function (listing, data) {
        var url = this.url + "listing/" + listing.data.id + "/complete";
        return this.http.put(url, JSON.stringify(data), { headers: this.getHeaders() }).pipe(catchError(this.handleError('ListingService::complete, url=' + url + ',data=' + JSON.stringify(data), {})));
    };
    ListingService.prototype.isComplete = function (id) {
        var url = this.url + "listing/" + id + "/is-complete";
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingService::isComplete, id=' + id, {}, {
            url: url,
            headers: this.getHeadersData(),
            method: 'GET'
        })));
    };
    ListingService.prototype.lagging = function (id, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + "listing/" + id + "/lagging" + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingService::lagging, id=' + id, {}, {
            url: url,
            headers: this.getHeadersData(),
            method: 'GET'
        })));
    };
    ListingService.prototype.reopenEnquiries = function (id) {
        var url = this.url + "listing/" + id + "/milestones/reopen-enquiries";
        return this.http.put(url, {}, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ListingService::reopenEnquiries, id=' + id, {})));
    };
    // TODO: rename
    ListingService.prototype.salesProgressor = function (id, options) {
        if (options === void 0) { options = {}; }
        var headers = this.getHeaders();
        var url = this.url + "listing/" + id + "/sales-negotiator" + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: headers }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingService::salesProgressor, id=' + id + ', url=' + url, {}, {
            url: url,
            headers: headers,
            method: 'GET'
        })));
    };
    ListingService.prototype.fallThrough = function (id) {
        var headers = this.getHeaders();
        var url = this.url + "listing/" + id + "/fall-through";
        return this.http.put(url, {}, { headers: headers }).pipe(catchError(this.handleError('ListingService::fallThrough, id=' + id, {})));
    };
    ListingService.prototype.discardFallThrough = function (id) {
        var headers = this.getHeaders();
        var url = this.url + "listing/" + id + "/discard-fall-through";
        return this.http.put(url, {}, { headers: headers }).pipe(catchError(this.handleError('ListingService::fallThrough, id=' + id, {})));
    };
    ListingService.prototype.prepListingForSubmission = function (listing, data) {
        var attributes = {
            address_id: listing.attributes.address_id,
            branch_id: listing.attributes.branch_id,
            price: listing.attributes.price,
        };
        return Object.assign(attributes, data);
    };
    ListingService.prototype.groupState = function (listing) {
        var url = this.url + "listing/" + listing.id + "/group-state";
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingService::groupState, url=' + url, {}, {
            url: url,
            headers: this.getHeadersData(),
            method: 'GET'
        })));
    };
    ListingService.prototype.offerGroupState = function (offerListingId) {
        var url = this.url + "offers/offer-listing/" + offerListingId + "/group-state";
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingService::groupState, url=' + url, {}, {
            url: url,
            headers: this.getHeadersData(),
            method: 'GET'
        })));
    };
    ListingService.prototype.removeCashBuyer = function (listing) {
        var url = this.url + "listing/" + listing.id + "/milestones/cash-buyer";
        return this.http.delete(url, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ListingService::removeCashBuyer, url=' + url, {})));
    };
    ListingService.prototype.removeMilestone = function (listing, milestone) {
        var url = this.url + "listing/" + listing.id + "/milestone/" + milestone.id;
        return this.http.delete(url, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ListingService::destroyMilestone, url=' + url, {})));
    };
    ListingService.prototype.removePossibleSearches = function (listing) {
        var url = this.url + "listing/" + listing.id + "/milestones/possible-searches";
        return this.http.delete(url, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ListingService::removePossibleSearches, url=' + url, {})));
    };
    ListingService.prototype.milestone = function (listing, milestone_type_key) {
        var url = this.url + "listing/" + listing.id + "/milestone/" + milestone_type_key;
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingService::milestone, url=' + url, {}, {
            url: url,
            headers: this.getHeadersData(),
            method: 'GET'
        })));
    };
    ListingService.prototype.chainedListings = function (id) {
        var url = this.url + "listing/" + id + "/relationships/chained-listings";
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingService::chainedListings, url=' + url, {}, {
            url: url,
            headers: this.getHeadersData(),
            method: 'GET'
        })));
    };
    ListingService.prototype.meta = function (id) {
        var url = this.url + "listing/" + id + "/relationships/meta";
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingService::meta, url=' + url, {}, {
            url: url,
            headers: this.getHeadersData(),
            method: 'GET'
        })));
    };
    ListingService.prototype.notes = function (id) {
        var url = this.url + "listing/" + id + "/relationships/notes";
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingService::notes, url=' + url, {}, {
            url: url,
            headers: this.getHeadersData(),
            method: 'GET'
        })));
    };
    ListingService.prototype.note = function (listingId, noteId) {
        var url = this.url + "listing/" + listingId + "/relationships/note/" + noteId;
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingService::note, url=' + url, {}, {
            url: url,
            headers: this.getHeadersData(),
            method: 'GET'
        })));
    };
    ListingService.prototype.milestoneTimeline = function (id) {
        var url = this.url + "listing/" + id + "/milestone-timeline";
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingService::milestoneTimeline, url=' + url, {}, {
            url: url,
            headers: this.getHeadersData(),
            method: 'GET'
        })));
    };
    ListingService.prototype.possibleSalesNegotiators = function (id) {
        var url = this.url + "listing/" + id + "/possible-sales-negotiators";
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingService::possibleSalesNegotiators, url=' + url, {}, {
            url: url,
            headers: this.getHeadersData(),
            method: 'GET'
        })));
    };
    ListingService.prototype.predictions = function (id) {
        var url = this.url + "listing/" + id + "/predictions";
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingService::predictions, url=' + url, {}, {
            url: url,
            headers: this.getHeadersData(),
            method: 'GET'
        })));
    };
    ListingService.prototype.chainPredictions = function (id) {
        var url = this.url + "listing/" + id + "/chain-predictions";
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingService::chainPredictions, url=' + url, {}, {
            url: url,
            headers: this.getHeadersData(),
            method: 'GET'
        })));
    };
    ListingService.prototype.verifyListing = function (id) {
        var url = this.url + "listing/" + id + "/verify";
        return this.http.post(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingService::verifyListing, url=' + url, {})));
    };
    ListingService.prototype.removeUnverifyListing = function (id) {
        var url = this.url + "listing/" + id + "/remove-unverify";
        return this.http.post(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingService::removeUnverifyListing, url=' + url, {})));
    };
    return ListingService;
}(ApiService));
export { ListingService };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-break-chain.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./confirm-break-chain.component";
var styles_ConfirmBreakChainComponent = [i0.styles];
var RenderType_ConfirmBreakChainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmBreakChainComponent, data: {} });
export { RenderType_ConfirmBreakChainComponent as RenderType_ConfirmBreakChainComponent };
export function View_ConfirmBreakChainComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "content mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "question"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Are you sure you want to break this link?"])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "span", [["class", "address"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", " "])), (_l()(), i1.ɵted(-1, null, ["and "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 8, "div", [["class", "action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "button", [["id", "break"], ["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).dialogRef.close(i1.ɵnov(_v, 13).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(13, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Break Link"])), (_l()(), i1.ɵeld(15, 0, null, null, 3, "button", [["id", "cancel"], ["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).dialogRef.close(i1.ɵnov(_v, 17).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(16, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(17, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Keep Link"]))], function (_ck, _v) { var currVal_5 = true; _ck(_v, 13, 0, currVal_5); var currVal_9 = false; _ck(_v, 17, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listing.seller.address.address_line_1; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.nextListing.seller.address.address_line_1; _ck(_v, 9, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 12).disabled || null); var currVal_3 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); var currVal_4 = (i1.ɵnov(_v, 13).ariaLabel || null); _ck(_v, 11, 0, currVal_2, currVal_3, currVal_4); var currVal_6 = (i1.ɵnov(_v, 16).disabled || null); var currVal_7 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); var currVal_8 = (i1.ɵnov(_v, 17).ariaLabel || null); _ck(_v, 15, 0, currVal_6, currVal_7, currVal_8); }); }
export function View_ConfirmBreakChainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vmc-confirm", [], null, null, null, View_ConfirmBreakChainComponent_0, RenderType_ConfirmBreakChainComponent)), i1.ɵdid(1, 114688, null, 0, i8.ConfirmBreakChainComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmBreakChainComponentNgFactory = i1.ɵccf("vmc-confirm", i8.ConfirmBreakChainComponent, View_ConfirmBreakChainComponent_Host_0, {}, {}, []);
export { ConfirmBreakChainComponentNgFactory as ConfirmBreakChainComponentNgFactory };

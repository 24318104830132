import * as tslib_1 from "tslib";
import { Base } from './base';
var Category = /** @class */ (function (_super) {
    tslib_1.__extends(Category, _super);
    function Category(data) {
        if (data === void 0) { data = null; }
        var _this = _super.call(this, data) || this;
        _this.categoryTitle = '';
        _this.categorySlug = '';
        _this.categoryDescription = '';
        _this.categoryTitle = _this.propertyIsValid(data, 'categoryTitle') ? data.categoryTitle : '';
        _this.categorySlug = _this.propertyIsValid(data, 'categorySlug') ? data.categorySlug : '';
        _this.categoryDescription = _this.propertyIsValid(data, 'categoryDescription') ? data.categoryDescription : '';
        return _this;
    }
    return Category;
}(Base));
export { Category };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./verify-listing.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i9 from "@angular/material/icon";
import * as i10 from "./verify-listing.component";
var styles_VerifyListingComponent = [i0.styles];
var RenderType_VerifyListingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VerifyListingComponent, data: {} });
export { RenderType_VerifyListingComponent as RenderType_VerifyListingComponent };
export function View_VerifyListingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "header mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Unqualified Chain Link"])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "button", [["class", "close-button"], ["mat-icon-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).dialogRef.close(i1.ɵnov(_v, 6).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(6, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "mat-icon", [["class", "close-icon mat-icon notranslate"], ["color", ""], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(8, 9158656, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "content mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵted(-1, null, [" This is an Unqualified Chain Link which ViewMyChain has added. "])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please click Verify to confirm the link or Remove to break the chain."])), (_l()(), i1.ɵeld(15, 0, null, null, 9, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(16, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(17, 0, null, null, 3, "button", [["class", "button is-primary"], ["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).dialogRef.close(i1.ɵnov(_v, 19).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.verify() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(18, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(19, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Verify"])), (_l()(), i1.ɵeld(21, 0, null, null, 3, "button", [["class", "button btn-remove"], ["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).dialogRef.close(i1.ɵnov(_v, 23).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.remove() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(22, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(23, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Remove"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_4 = true; _ck(_v, 6, 0, currVal_4); var currVal_7 = ""; _ck(_v, 8, 0, currVal_7); var currVal_11 = false; _ck(_v, 19, 0, currVal_11); var currVal_15 = false; _ck(_v, 23, 0, currVal_15); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 5).disabled || null); var currVal_2 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); var currVal_3 = (i1.ɵnov(_v, 6).ariaLabel || null); _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 8).inline; var currVal_6 = (((i1.ɵnov(_v, 8).color !== "primary") && (i1.ɵnov(_v, 8).color !== "accent")) && (i1.ɵnov(_v, 8).color !== "warn")); _ck(_v, 7, 0, currVal_5, currVal_6); var currVal_8 = (i1.ɵnov(_v, 18).disabled || null); var currVal_9 = (i1.ɵnov(_v, 18)._animationMode === "NoopAnimations"); var currVal_10 = (i1.ɵnov(_v, 19).ariaLabel || null); _ck(_v, 17, 0, currVal_8, currVal_9, currVal_10); var currVal_12 = (i1.ɵnov(_v, 22).disabled || null); var currVal_13 = (i1.ɵnov(_v, 22)._animationMode === "NoopAnimations"); var currVal_14 = (i1.ɵnov(_v, 23).ariaLabel || null); _ck(_v, 21, 0, currVal_12, currVal_13, currVal_14); }); }
export function View_VerifyListingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vmc-confirm", [], null, null, null, View_VerifyListingComponent_0, RenderType_VerifyListingComponent)), i1.ɵdid(1, 114688, null, 0, i10.VerifyListingComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VerifyListingComponentNgFactory = i1.ɵccf("vmc-confirm", i10.VerifyListingComponent, View_VerifyListingComponent_Host_0, {}, {}, []);
export { VerifyListingComponentNgFactory as VerifyListingComponentNgFactory };

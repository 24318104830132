<footer class="section" [ngClass]="{'is-hidden' : !content}">
  <div class="container">
    <div class="columns is-multiline">

<!--      <div class="column is-12 partners" *ngIf="content.footerPartners">-->
<!--        <h4 class="partners__title">{{ content.footerPartners.partnersBlockTitle}}</h4>-->
<!--        <ul class="partners__list">-->
<!--          <li *ngFor="let partner of content.footerPartners.partnersListing">-->
<!--            <a href="{{ partner.partnerUrl }}" target="_blank" rel="noopener" id="footer-partner-link-{{ partner.partnerName }}">-->
<!--              <img [offset]="100" [lazyLoad]="partner.partnerImage" alt="{{ partner.partnerName }}">-->
<!--            </a>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->

      <div class="column columns menus">
        <div class="column" *ngFor="let footerMenu of content.footerMenus" style="max-width: 250px;">
          <vmc-menu [menu]="footerMenu" [itemClass]="'button is-white'" [itemType]="'footer'"></vmc-menu>
        </div>
        <div class="column social-networks">
          <vmc-menu [menu]="content.socialNetworks" itemType="social"></vmc-menu>
        </div>
      </div>

      <!-- <div class="column is-one-third"> -->
        <!--TODO taken out until we can either get exact targets API to work or we use another service-->
        <!--<div class="sign-up">-->

        <!--<form class="subscription">-->
        <!--<h4 class="subscription__title">Sign up for updates</h4>-->
        <!--<div class="field has-addons">-->
        <!--<p class="control has-icons-left is-expanded">-->
        <!--<input class="input" type="email" placeholder="Email">-->
        <!--<span class="icon is-small is-left">-->
        <!--<i class="fa fa-envelope"></i>-->
        <!--</span>-->
        <!--</p>-->
        <!--<div class="control">-->
        <!--<a class="button is-primary">-->
        <!--<span class="icon"><i class="fa fa-chevron-circle-right"></i> </span>-->
        <!--</a>-->
        <!--</div>-->
        <!--</div>-->
        <!--</form>-->
        <!--</div>-->
        <!-- <vmc-menu [menu]="content.socialNetworks" itemType="social"></vmc-menu> -->
      <!-- </div> -->

      <div class="column is-12" style="padding-top: 0;">
        <p class="copyright">© {{ currentYear }} View My Chain Ltd. | All Rights Reserved | Patent Pending: UK application No. 1615855.2 | 8 Whittle Court, Knowlhill, Milton Keynes, MK5 8FT</p>
      </div>

    </div>
  </div>
</footer>

<header class="header" [ngClass]="{'is-active-canvas': isMenuOpened}">

  <!--Desktop-->
  <div class="container">
    <nav class="navbar is-hidden-touch" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item" routerLink="{{ home_link }}" id="header-desktop-home">
          <img src="{{ content.headerLogo }}" alt="View My Chain Logo">
        </a>
      </div>
      <div class="navbar-menu">
        <!-- navbar start, navbar end -->
        <div class="navbar-start">
        </div>

        <div class="navbar-end">
          <!-- navbar items -->
<!--          <ng-container *ngFor="let item of content.headerMainMenu.menuItems">-->
<!--            <a *ngIf="item.isExternal" href="{{ item.menuUrl }}" rel="noopener" class="navbar-item" id="header-desktop-menu-link-external-{{ item.menuName }}">-->
<!--              {{ item.menuName }}-->
<!--            </a>-->
<!--            <a *ngIf="!item.isExternal" routerLink="{{ item.menuUrl }}" class="navbar-item" routerLinkActive="is-active" id="header-desktop-menu-link-internal-{{ item.menuName }}">-->
<!--              {{ item.menuName }}-->
<!--            </a>-->
<!--          </ng-container>-->

          <!--Contact Us-->
          <a class="navbar-item" routerLink="/contact-us" routerLinkActive="is-active" id="header-desktop-contact">
<!--            <span class="icon"><i class="fa fa-question-circle"></i> </span>-->
            <span class="text">Contact Us</span>
          </a>
          <a class="navbar-item" routerLink="/support" routerLinkActive="is-active" id="header-desktop-support" #supportLink (click)="reloadPage(supportLink)">
            <!--            <span class="icon"><i class="fa fa-question-circle"></i> </span>-->
            <span class="text">Support</span>
          </a>

          <!--Drop Down-->
<!--          <div class="navbar-item">-->
<!--            <div class="field">-->
<!--              <p class="control">-->
<!--                <a class="button is-success" routerLink="/register" id="header-desktop-register">Try Now</a>-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="navbar-item">-->
<!--            <div class="field">-->
<!--              <p class="control">-->
<!--                <a class="button is-success" routerLink="/request-a-demo" id="header-desktop-agent-live-demo">Request A Demo</a>-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--          <a class="navbar-item" routerLink="/login" routerLinkActive="is-active" id="header-desktop-login">Login</a>-->
        </div>
      </div>
    </nav>
  </div>

  <!--Mobile-->
  <div class="header-mobile is-hidden-desktop">
    <nav class="container nav-mobile navbar">
      <div class="navbar-brand">
        <a class='navbar-item' routerLink="{{ home_link }}" id="header-mobile-home">
          <img src="{{ content.headerLogo }}" alt="View My Chain Logo">
        </a>
        <div class="navbar-burger" (click)="toggleMenu()" [ngClass]="{'is-active': isMenuOpened}" id="header-mobile-hamburger">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </nav>
    <aside class="menu menu-canvas" [ngClass]="{'is-active': isMenuOpened}" style="z-index: 1000;">
      <div class="nav-primary">
        <vmc-menu [menu]="content.headerMainMenu" [itemClass]="'button is-white'" [itemType]="'header'"></vmc-menu>
      </div>
      <hr>
      <div class="navbar-actions">
        <ul>
          <li>
            <a class="button is-primary is-inverted has-text-left" href="tel:{{ content.headerTopCta.ctaUrl }}" id="header-mobile-tel">
              {{ content.headerTopCta.ctaText }}
            </a>
          </li>
          <li>
            <a class="button is-primary is-inverted has-text-left" routerLink="/contact-us" routerLinkActive="is-active" id="header-mobile-contact">
              <span>Contact Us</span>
            </a>
          </li>

<!--          <li>-->
<!--            <a class="button is-primary is-inverted has-text-left" routerLink="/register" routerLinkActive="is-active" id="header-mobile-register">-->
<!--              <span>Try Now</span>-->
<!--            </a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a class="button is-primary is-inverted has-text-left" routerLink="/request-a-demo" routerLinkActive="is-active" id="header-mobile-agent-live-demo">-->
<!--              <span>Request A Demo</span>-->
<!--            </a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a class="button is-primary is-inverted has-text-left" routerLink="/agent-live-demo" routerLinkActive="is-active" id="header-mobile-agent-live-demo">-->
<!--              <span>Agent Live Demo</span>-->
<!--            </a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a class="button is-primary is-inverted has-text-left" routerLink="/login" routerLinkActive="is-active" id="header-mobile-login">Login</a>-->
<!--          </li>-->
        </ul>
      </div>
    </aside>
  </div>
  <div class="overlay is-hidden is-hidden-tablet"></div>
</header>

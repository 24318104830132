import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, retryWhen } from 'rxjs/operators';
var ChainService = /** @class */ (function (_super) {
    tslib_1.__extends(ChainService, _super);
    function ChainService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChainService.prototype.check = function (chain, top) {
        var url = this.url + 'chain/' + chain + '/check';
        var data = {
            reason: 'Checked',
            top: top,
        };
        return this.http.post(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ChainService::check, url=' + url + ', data=' + JSON.stringify(data), {})));
    };
    ChainService.prototype.uncheck = function (chain, top) {
        var url = this.url + 'chain/' + chain + '/uncheck';
        var data = {
            reason: 'Not EOC',
            top: top,
        };
        return this.http.post(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ChainService::uncheck, url=' + url + ', data=' + JSON.stringify(data), {})));
    };
    ChainService.prototype.link = function (buyer, seller, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'chain/add/link' + ApiService.httpBuildQuery(options);
        var data = {
            buyer: buyer,
            seller: seller,
        };
        return this.http.post(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ChainService::link, url=' + url + ', data=' + JSON.stringify(data), {})));
    };
    ChainService.prototype.uncouple = function (buyer, seller) {
        var url = this.url + 'chain/remove/link';
        var data = {
            buyer: buyer.id,
            seller: seller.id,
        };
        return this.http.post(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ChainService::uncouple, url=' + url + ', data=' + JSON.stringify(data), {})));
    };
    ChainService.prototype.updateBrokenStatus = function (listingId, isBroken) {
        var url = this.url + ("listing/chain/" + listingId + "/" + (isBroken ? 'break' : 'unbreak'));
        return this.http.put(url, {}, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ChainService::markAsBrokenChain, url=' + url + ', data=' + JSON.stringify({}), {})));
    };
    ChainService.prototype.listings = function (chain_link_id, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'chain/' + chain_link_id + '/listings' + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ChainService::listings, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    ChainService.prototype.uncoupleOfferListing = function (buyerId, sellerId, offerId) {
        var url = this.url + 'offers/chain/remove/link';
        var data = {
            buyer_id: buyerId,
            seller_id: sellerId,
            offer_id: offerId
        };
        return this.http.post(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ChainService::uncouple, url=' + url + ', data=' + JSON.stringify(data), {})));
    };
    ChainService.prototype.splitChainBreak = function (listingId, nextListingId) {
        var url = this.url + ("chain/" + listingId + "/break/" + nextListingId);
        var response = this.http.post(url, {}, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ChainService::uncouple, url=' + url + ', data=' + JSON.stringify({}), {})));
        return response;
    };
    return ChainService;
}(ApiService));
export { ChainService };

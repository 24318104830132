import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError } from 'rxjs/operators';
var MilestoneChecklistItemService = /** @class */ (function (_super) {
    tslib_1.__extends(MilestoneChecklistItemService, _super);
    function MilestoneChecklistItemService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MilestoneChecklistItemService.prototype.add = function (data) {
        var url = this.url + 'milestone_checklist_item';
        return this.http.post(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('MilestoneChecklistItemService::add, url=' + url, {})));
    };
    MilestoneChecklistItemService.prototype.update = function (id, data) {
        var url = this.url + 'milestone_checklist_item';
        return this.http.put(url + ("/" + id), data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('MilestoneChecklistItemService::update, url=' + url, {})));
    };
    MilestoneChecklistItemService.prototype.delete = function (id) {
        var url = this.url + 'milestone_checklist_item';
        return this.http.delete(url + ("/" + id), { headers: this.getHeaders() }).pipe(catchError(this.handleError('MilestoneChecklistItemService::delete, url=' + url + ', data=' + id, {})));
    };
    return MilestoneChecklistItemService;
}(ApiService));
export { MilestoneChecklistItemService };

import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, retryWhen } from 'rxjs/operators';
var MilestoneReasonService = /** @class */ (function (_super) {
    tslib_1.__extends(MilestoneReasonService, _super);
    function MilestoneReasonService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MilestoneReasonService.prototype.getReasons = function (options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'listing/milestone/reasons' + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('MilestoneReasonService::getReasons, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    MilestoneReasonService.prototype.getListingReason = function (listingId, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'listing/' + listingId + '/milestone-reason' + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('MilestoneReasonService::getListingReason, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    MilestoneReasonService.prototype.putListingReason = function (listingId, data) {
        var url = this.url + 'listing/' + listingId + '/milestone-reason';
        return this.http.put(url, data, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('MilestoneReasonService::putListingReason, url=' + url, {})));
    };
    MilestoneReasonService.prototype.getOfferListingReason = function (offerListingId, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'offers/offer-listing/' + offerListingId + '/milestone-reason' + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('MilestoneReasonService::getListingReason, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    MilestoneReasonService.prototype.putOfferListingReason = function (offerListingId, data) {
        var url = this.url + 'offers/offer-listing/' + offerListingId + '/milestone-reason';
        return this.http.put(url, data, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('MilestoneReasonService::putListingReason, url=' + url, {})));
    };
    return MilestoneReasonService;
}(ApiService));
export { MilestoneReasonService };

<ul [ngClass]="menu.menuClass" *ngIf="menu">
  <li *ngFor="let item of menu.menuItems">
    <a *ngIf="item.isExternal" href="{{ item.menuUrl }}" target="_blank" rel="noopener" [ngClass]="item.menuItemClass" id="menu-external-item-{{ itemType }}-{{ item.menuId }}">
      {{ item.menuName }}
    </a>
    <a *ngIf="!item.isExternal" href="{{ item.menuUrl }}" rel="noopener" [ngClass]="item.menuItemClass" id="menu-external-item-{{ itemType }}-{{ item.menuId }}">
      {{ item.menuName }}
    </a>
  </li>
</ul>

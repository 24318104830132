<header>
  <div class='columns'>
    <div class='column'>
      <h3 mat-dialog-title>Add an address</h3>
    </div>
    <div class='column'>
      <button class="delete is-pulled-right" (click)="close()" id="verify-listing-address-close"></button>
    </div>
  </div>
</header>
<section mat-dialog-content>
  <vmc-address-finder [defaultValue]="listing.data.attributes.address_postal_code" (onSelect)="setAddressId($event) " *ngIf="!addressId" (onClose)="close()"></vmc-address-finder>

  <span class="help" *ngIf="addressId"><a (click)="resetAddress()" id="verify-listing-address-change">Edit</a> </span>

  <div class="address-container" *ngIf="addressId">
    {{ selected_address }}
  </div>

  <div style="margin-bottom: 8px" *ngIf="addressId">
    To view chains and milestones please leave this listing overnight for them to build automatically.  You can build chains yourself if you need to see them immediately.
  </div>

  <!-- <p class='help is-danger' *ngIf="error_message">
    {{ error_message }}
  </p> -->
  <p class='help is-danger' *ngIf="errors && errors.address_id">
    {{ errors.address_id }}
  </p>

  <p class="control">
    <button class="button is-primary is-medium" (click)="apply()" type="button" [disabled]="!addressId" [ngClass]="{'is-loading' : running}" id="verify-listing-address-verify" style="margin-right: 1rem;">
      <span>Apply</span></button>
    <button class="button is-medium" (click)="close()" type="button" id="verify-listing-address-cancel"><span>Cancel</span></button>
  </p>
</section>

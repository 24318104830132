import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, retryWhen } from 'rxjs/operators';
var EnvironmentalSearchProviderService = /** @class */ (function (_super) {
    tslib_1.__extends(EnvironmentalSearchProviderService, _super);
    function EnvironmentalSearchProviderService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EnvironmentalSearchProviderService.prototype.get = function (id, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'environmental_search_provider/' + id + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('EnvironmentalSearchProviderService::get, url=' + url + ', id=' + id, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    EnvironmentalSearchProviderService.prototype.all = function (options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'environmental_search_provider' + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('EnvironmentalSearchProviderService::all, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    return EnvironmentalSearchProviderService;
}(ApiService));
export { EnvironmentalSearchProviderService };

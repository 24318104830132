<form (ngSubmit)="submit()" [formGroup]="addressForm">
  <div class="columns">
    <div class="column">
      <label class="has-text-weight-semibold is-two-fifths">House number/name</label>
      <mat-form-field>
        <input type="text" matInput formControlName="homeNumber" placeholder="e.g. 8 or Whittle house" id="address-lookup-search-home-number-{{ id }}" autocomplete="off">
      </mat-form-field>
    </div>
    <div class="column is-two-fifths">
      <label class="has-text-weight-semibold">Search for a postcode</label>
      <mat-form-field>
        <input type="text" matInput class="" formControlName="postcode" placeholder="e.g. MK5 8FT" id="address-lookup-search-postcode-{{ id }}" maxlength="8" autocomplete="off">
      </mat-form-field>
      <ng-template [ngIf]="addressForm.controls.postcode.invalid && (addressForm.controls.postcode.dirty)">
        <mat-error class="help has-text-danger postcode-error" *ngIf="!!addressForm.controls.postcode.errors.required">
          Please enter a postcode
        </mat-error>
        <mat-error class="help has-text-danger postcode-error" *ngIf="addressForm.controls.postcode.errors.minlength || addressForm.controls.postcode.errors.maxlength">
          Please enter a valid postcode
        </mat-error>
      </ng-template>
    </div>
    <div class="column is-one-fifths button-margin">
      <button class="button is-primary is-small bg-light-orange" [ngClass]="{'is-loading': running}" type="submit" [disabled]="!addressForm.valid">Search</button>
    </div>
  </div>

  <ng-template [ngIf]="searched">
    <ng-template [ngIf]="addresses && addresses.length > 0">
      <div class="columns">
        <div class="column">
          <label class="has-text-weight-semibold is-two-fifths">Address</label>
          <mat-form-field>
            <input type="text" placeholder="Select an address" matInput [formControl]="myControl" [matAutocomplete]="auto">
          </mat-form-field>

          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="select($event)">
            <mat-option *ngFor="let filteredOption of filteredOptions | async" [value]="filteredOption">{{ filteredOption.attributes.full_address }}</mat-option>

            <mat-option *ngIf="displayCreateOption && listing" class="create-address-option" id="address-lookup-results-create-address-{{ id }}">
              {{ unableToAddressMatchOptionLabel }}
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>
    </ng-template>
    <ng-template [ngIf]="!running && addresses && addresses.length == 0">
      <div class="columns">
        <div class="column">
          <label class="has-text-weight-semibold is-two-fifths">Address</label>
          <p>We didn't find any results for <span class="has-text-weight-semibold">{{ postcode }}</span></p>
        </div>
      </div>
    </ng-template>
  </ng-template>
</form>

import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError } from 'rxjs/operators';
var RegistrationService = /** @class */ (function (_super) {
    tslib_1.__extends(RegistrationService, _super);
    function RegistrationService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RegistrationService.prototype.add = function (data, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'registration' + ApiService.httpBuildQuery(options);
        return this.http.post(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('RegistrationService::add, url=' + url, {})));
    };
    // TODO: Move to its own service
    RegistrationService.prototype.register = function (data) {
        var url = this.url + 'register';
        return this.http.post(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('RegistrationService::register, url=' + url, {})));
    };
    return RegistrationService;
}(ApiService));
export { RegistrationService };

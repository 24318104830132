<div class="columns is-mobile">
  <div class="column">
    <label class="has-text-weight-semibold">Address</label>
    <mat-form-field>
      <input type="text" #input matInput [formControl]="myControl" [matAutocomplete]="auto" placeholder="Search for an address" id="address-search-search-{{ id }}">
    </mat-form-field>

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="select($event)" id="address-search-results-{{ id }}" [displayWith]="display">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option" id="address-search-results-{{ id }}-{{ option.attributes.full_address }}">
        {{ option.attributes.full_address }}
      </mat-option>
      <mat-option *ngIf="displayCreateNewAddress" class="create-address-option" id="address-search-results-create-address-{{ id }}">
        {{ createOptionLabel }}
      </mat-option>
      <mat-option *ngIf="displayUnableToAddressMatch && listing" class="create-address-option" id="displayUnableToAddressMatch-results-create-address-{{ id }}">
        {{ unableToAddressMatchOptionLabel }}
      </mat-option>
    </mat-autocomplete>
  </div>
  <div class="column is-narrow is-vertically-centered is-flex">
    <mat-spinner *ngIf="running" [diameter]="20"></mat-spinner>
  </div>
</div>

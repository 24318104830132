import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, map, retryWhen } from 'rxjs/operators';
var AddressService = /** @class */ (function (_super) {
    tslib_1.__extends(AddressService, _super);
    function AddressService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AddressService.prototype.search = function (filters, options) {
        var url = this.url + 'address/filter' + ApiService.httpBuildQuery(options);
        return this.http.post(url, filters, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('AddressService::search, url=' + url + ', filters=' + JSON.stringify(filters), {}, {
            url: url,
            method: 'POST',
            headers: this.getHeadersData(),
            data: filters,
        })), map(function (response) { return response.data; }));
    };
    AddressService.prototype.create = function (data) {
        var url = this.url + 'address';
        return this.http.post(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('AddressService::create, url=' + url + ', data=' + JSON.stringify(data))));
    };
    AddressService.prototype.get = function (id, options) {
        var url = this.url + 'address/' + id + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(catchError(this.handleError('AddressService::get, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    AddressService.prototype.parent = function (id, options) {
        var url = this.url + 'address/' + id + '/relationships/parent' + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(catchError(this.handleError('AddressService::parent, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    return AddressService;
}(ApiService));
export { AddressService };

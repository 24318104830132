import * as tslib_1 from "tslib";
import { ApiResponse } from '../api-services/api-response';
var UserCollection = /** @class */ (function (_super) {
    tslib_1.__extends(UserCollection, _super);
    function UserCollection() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.data = [];
        return _this;
    }
    return UserCollection;
}(ApiResponse));
export { UserCollection };
var UserItem = /** @class */ (function (_super) {
    tslib_1.__extends(UserItem, _super);
    function UserItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.data = new User();
        return _this;
    }
    return UserItem;
}(ApiResponse));
export { UserItem };
var UserRelations = /** @class */ (function () {
    function UserRelations() {
    }
    return UserRelations;
}());
export { UserRelations };
var User = /** @class */ (function () {
    function User() {
        this.id = null;
        this.type = '';
        this.attributes = new UserAttributes();
    }
    return User;
}());
export { User };
var UserAttributes = /** @class */ (function () {
    function UserAttributes() {
        this.first_name = '';
        this.last_name = '';
        this.email = '';
        this.type = '';
        this.contact_id = null;
        this.created_at = null;
        this.updated_at = null;
        this.deleted_at = null;
    }
    return UserAttributes;
}());
export { UserAttributes };

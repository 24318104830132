
<section class="section section__not-found not-found">
  <p *ngIf="!content">NotFound</p>
  <div class="container" *ngIf="content">
    <div class="columns">
      <div class="column not-found__body">
        <h1 class="not-found__title">{{ content.heroTitle }}</h1>
        <p class="not-found__message">
          <vmc-markdown [data]="content.heroMessage"></vmc-markdown>
        </p>
        <div class="cta__list" *ngIf="content.heroCtAs">
          <vmc-cta *ngFor="let cta of content.heroCtAs" [cta]="cta"></vmc-cta>
        </div>
      </div>
      <figure class="column not-found__img">
        <img [offset]="100" [lazyLoad]="content.heroImage" alt="not-found-image">
      </figure>
    </div>
  </div>
</section>

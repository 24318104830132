import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, retryWhen } from 'rxjs/operators';
var BrokerService = /** @class */ (function (_super) {
    tslib_1.__extends(BrokerService, _super);
    function BrokerService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BrokerService.prototype.all = function (options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'broker' + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('BrokerService::all, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    return BrokerService;
}(ApiService));
export { BrokerService };

import * as tslib_1 from "tslib";
import { Base } from './base';
var Page = /** @class */ (function (_super) {
    tslib_1.__extends(Page, _super);
    function Page(data) {
        if (data === void 0) { data = null; }
        var _this = _super.call(this, data) || this;
        _this.pageTitle = '';
        _this.pageBlocks = [];
        _this.pageTemplate = '';
        _this.slug = '';
        _this.pageTitle = _this.propertyIsValid(data, 'pageTitle') ? data.pageTitle : '';
        _this.pageTemplate = _this.propertyIsValid(data, 'pageTemplate') ? data.pageTemplate : '';
        _this.slug = _this.propertyIsValid(data, 'slug') ? data.slug : '';
        // the render service sorts the construction of these elements
        _this.pageBlocks = _this.dataHasKey(data, 'pageBlocks') ? data['pageBlocks'] : [];
        return _this;
    }
    return Page;
}(Base));
export { Page };

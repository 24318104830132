/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./funding-consent.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../markdown/markdown.component.ngfactory";
import * as i3 from "../../markdown/markdown.component";
import * as i4 from "../../../../node_modules/@angular/material/checkbox/typings/index.ngfactory";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/material/checkbox";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i10 from "@angular/material/button";
import * as i11 from "@angular/cdk/platform";
import * as i12 from "@angular/material/dialog";
import * as i13 from "./funding-consent.component";
import * as i14 from "../../api-services/contact.service";
var styles_FundingConsentComponent = [i0.styles];
var RenderType_FundingConsentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FundingConsentComponent, data: {} });
export { RenderType_FundingConsentComponent as RenderType_FundingConsentComponent };
export function View_FundingConsentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [["class", "title is-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "vmc-markdown", [], null, null, null, i2.View_MarkdownComponent_0, i2.RenderType_MarkdownComponent)), i1.ɵdid(5, 573440, null, 0, i3.MarkdownComponent, [], { data: [0, "data"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "mat-checkbox", [["class", "mat-checkbox"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.setFundingConsent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatCheckbox_0, i4.RenderType_MatCheckbox)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.MatCheckbox]), i1.ɵdid(10, 8568832, null, 0, i6.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i7.FocusMonitor, i1.NgZone, [8, null], [2, i6.MAT_CHECKBOX_CLICK_ACTION], [2, i8.ANIMATION_MODULE_TYPE]], { checked: [0, "checked"] }, { change: "change" }), (_l()(), i1.ɵeld(11, 0, null, null, 3, "button", [["class", "button is-light is-pulled-right"], ["id", "confirm-cancel"], ["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).dialogRef.close(i1.ɵnov(_v, 13).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MatButton_0, i9.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i10.MatButton, [i1.ElementRef, i11.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(13, 606208, null, 0, i12.MatDialogClose, [[2, i12.MatDialogRef], i1.ElementRef, i12.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.content.fundingConsentBody; _ck(_v, 5, 0, currVal_1); var currVal_10 = _co.contact.attributes.funding_consent; _ck(_v, 10, 0, currVal_10); var currVal_14 = false; _ck(_v, 13, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content.fundingConsentSubtitle; _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.content.fundingConsentQuestion; _ck(_v, 7, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 10).id; var currVal_4 = null; var currVal_5 = i1.ɵnov(_v, 10).indeterminate; var currVal_6 = i1.ɵnov(_v, 10).checked; var currVal_7 = i1.ɵnov(_v, 10).disabled; var currVal_8 = (i1.ɵnov(_v, 10).labelPosition == "before"); var currVal_9 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_11 = (i1.ɵnov(_v, 12).disabled || null); var currVal_12 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); var currVal_13 = (i1.ɵnov(_v, 13).ariaLabel || null); _ck(_v, 11, 0, currVal_11, currVal_12, currVal_13); }); }
export function View_FundingConsentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vmc-funding-consent", [], null, null, null, View_FundingConsentComponent_0, RenderType_FundingConsentComponent)), i1.ɵdid(1, 114688, null, 0, i13.FundingConsentComponent, [i12.MatDialogRef, i12.MAT_DIALOG_DATA, i14.ContactService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FundingConsentComponentNgFactory = i1.ɵccf("vmc-funding-consent", i13.FundingConsentComponent, View_FundingConsentComponent_Host_0, { contact: "contact" }, {}, []);
export { FundingConsentComponentNgFactory as FundingConsentComponentNgFactory };

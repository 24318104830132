import * as tslib_1 from "tslib";
import { Base } from './base';
var Key = /** @class */ (function (_super) {
    tslib_1.__extends(Key, _super);
    function Key(data) {
        if (data === void 0) { data = null; }
        var _this = _super.call(this, data) || this;
        _this.keyEntryTitle = '';
        _this.keyTitle = '';
        _this.keyBody = '';
        _this.keyEntryTitle = _this.propertyIsValid(data, 'keyEntryTitle') ? data.keyEntryTitle : '';
        _this.keyTitle = _this.propertyIsValid(data, 'keyTitle') ? data.keyTitle : '';
        _this.keyBody = _this.propertyIsValid(data, 'keyBody') ? data.keyBody : '';
        return _this;
    }
    return Key;
}(Base));
export { Key };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./markdown.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../pipes/safe.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/common";
import * as i5 from "./markdown.component";
var styles_MarkdownComponent = [i0.styles];
var RenderType_MarkdownComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_MarkdownComponent, data: {} });
export { RenderType_MarkdownComponent as RenderType_MarkdownComponent };
export function View_MarkdownComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafePipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { content: 0 }), i1.ɵppd(4, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 3, 0, _co.isContent); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), _co.convertedData)); _ck(_v, 1, 0, currVal_0); }); }
export function View_MarkdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vmc-markdown", [], null, null, null, View_MarkdownComponent_0, RenderType_MarkdownComponent)), i1.ɵdid(1, 573440, null, 0, i5.MarkdownComponent, [], null, null)], null, null); }
var MarkdownComponentNgFactory = i1.ɵccf("vmc-markdown", i5.MarkdownComponent, View_MarkdownComponent_Host_0, { data: "data", isContent: "isContent" }, {}, []);
export { MarkdownComponentNgFactory as MarkdownComponentNgFactory };

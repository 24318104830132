import './polyfills.ts';
import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
var enableGoogleTagManager = function () {
    var googleTagManagerSnippet = "\n    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n    'https://www.googletagmanager.com/gtm.js?id='+i+dl+\n    '&gtm_auth=" + environment.gtm_auth + "&gtm_preview=" + environment.gtm_preview + "&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);\n    })(window,document,'script','dataLayer','" + environment.gtm_container_id + "');\n  ";
    document.write('<script type="text/javascript">' + googleTagManagerSnippet + '</script>');
};
var ɵ0 = enableGoogleTagManager;
if (environment.name !== 'development') {
    enableProdMode();
    // enableGoogleTagManager();
}
__NgCli_bootstrap_2.platformBrowser()
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .then(function () {
    if ('serviceWorker' in navigator && environment.production) {
        navigator.serviceWorker.register('/ngsw-worker.js');
    }
})
    .catch(function (err) { return console.log(err); });
export { ɵ0 };

<header style="text-align: center;">
  <h3 mat-dialog-title style="font-weight: bold;">Error: Chained Property Conflict</h3>
</header>
<mat-dialog-content>
  <p>
    At least one of the chained properties is already in another chain. Please break the chain in the active listing or <a [href]="buildMailTo()"> click here </a> to contact us.
  </p>
  <p style="font-weight: bold; margin-top: 16px;">Here are the conflict properties:</p>
  <div *ngFor="let errOffer of errOfferListings; let i = index">
    <div *ngIf="branchIds.includes(errOffer.attributes.branch_id) || isAdmin; else otherBranch" style="display: flex; gap: 16px; margin-top: 16px;">
      <a [href]="'/listings/' + errOffer.attributes.listing_id">{{i + 1}}. {{errOffer.attributes.display_address}}</a>
      <span class="icon">
        <i class="fa fa-check-circle" aria-hidden="true" style="font-size: 24px; color: #0061a8"></i>
      </span>
      <a [href]="'/listings/' + errOffer.attributes.listing_id">Go to the property</a>
    </div>
    <ng-template #otherBranch>
      <div style="display: flex; gap: 16px; margin-top: 16px;">
        <p>{{i + 1}}. {{errOffer.attributes?.display_address}}</p>
        <span class="icon">
          <i class="fa fa-times-circle" aria-hidden="true" style="font-size: 24px; color: #d3033d"></i>
        </span>
        No permission. <a [href]="buildMailTo()">Contact us</a>
      </div>
    </ng-template>
  </div>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: center;">
  <button mat-raised-button (click)="dialogRef.close(true)" id="alert-ok" style="margin: 20px 0; padding: 0 60px">Close</button>
</mat-dialog-actions>
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./address-finder.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../address-lookup/address-lookup.component.ngfactory";
import * as i3 from "../address-lookup/address-lookup.component";
import * as i4 from "../../api-services/address.service";
import * as i5 from "../../auth/authentication.service";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/material/dialog";
import * as i8 from "@angular/router";
import * as i9 from "../../api-services/listing.service";
import * as i10 from "../address-search/address-search.component.ngfactory";
import * as i11 from "../address-search/address-search.component";
import * as i12 from "@angular/common";
import * as i13 from "./address-finder.component";
var styles_AddressFinderComponent = [i0.styles];
var RenderType_AddressFinderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddressFinderComponent, data: {} });
export { RenderType_AddressFinderComponent as RenderType_AddressFinderComponent };
function View_AddressFinderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vmc-address-lookup", [], null, [[null, "onSelect"], [null, "onClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelect" === en)) {
        var pd_0 = (_co.select($event) !== false);
        ad = (pd_0 && ad);
    } if (("onClose" === en)) {
        var pd_1 = (_co.close($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_AddressLookupComponent_0, i2.RenderType_AddressLookupComponent)), i1.ɵdid(1, 114688, null, 0, i3.AddressLookupComponent, [i4.AddressService, i5.AuthenticationService, i6.FormBuilder, i7.MatDialog, i8.Router, i9.ListingService], { displayCreateOption: [0, "displayCreateOption"], listing: [1, "listing"], id: [2, "id"], rows: [3, "rows"] }, { onSelect: "onSelect", onClose: "onClose" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayCreateOption; var currVal_1 = _co.listing; var currVal_2 = _co.id; var currVal_3 = _co.rows; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_AddressFinderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vmc-address-search", [], null, [[null, "onSelect"], [null, "onClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelect" === en)) {
        var pd_0 = (_co.select($event) !== false);
        ad = (pd_0 && ad);
    } if (("onClose" === en)) {
        var pd_1 = (_co.close($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i10.View_AddressSearchComponent_0, i10.RenderType_AddressSearchComponent)), i1.ɵdid(1, 1163264, null, 0, i11.AddressSearchComponent, [i4.AddressService, i5.AuthenticationService, i7.MatDialog, i8.Router, i9.ListingService], { defaultValue: [0, "defaultValue"], rows: [1, "rows"], displayCreateOption: [2, "displayCreateOption"], id: [3, "id"], listing: [4, "listing"] }, { onSelect: "onSelect", onClose: "onClose" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.defaultValue; var currVal_1 = _co.rows; var currVal_2 = _co.displayCreateOption; var currVal_3 = _co.id; var currVal_4 = _co.listing; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_AddressFinderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "component"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressFinderComponent_1)), i1.ɵdid(3, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressFinderComponent_2)), i1.ɵdid(5, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "button is-text is-size-7 is-paddingless has-text-primary txt-light-orange"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showAdvanced = !_co.showAdvanced) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showAdvanced; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.showAdvanced; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.showAdvanced ? "Basic" : "Advanced"); _ck(_v, 7, 0, currVal_2); }); }
export function View_AddressFinderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vmc-address-finder", [], null, null, null, View_AddressFinderComponent_0, RenderType_AddressFinderComponent)), i1.ɵdid(1, 114688, null, 0, i13.AddressFinderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddressFinderComponentNgFactory = i1.ɵccf("vmc-address-finder", i13.AddressFinderComponent, View_AddressFinderComponent_Host_0, { defaultValue: "defaultValue", rows: "rows", displayCreateOption: "displayCreateOption", id: "id", listing: "listing" }, { onSelect: "onSelect", onClose: "onClose" }, []);
export { AddressFinderComponentNgFactory as AddressFinderComponentNgFactory };

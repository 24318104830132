import * as tslib_1 from "tslib";
import { Menu } from './menu';
import { Cta } from './cta';
import { Base } from './base';
var Header = /** @class */ (function (_super) {
    tslib_1.__extends(Header, _super);
    function Header(data) {
        if (data === void 0) { data = null; }
        var _this = _super.call(this, data) || this;
        _this.headerEntryTitle = '';
        _this.headerLogo = '';
        _this.headerTopMenu = new Menu();
        _this.headerTopCta = new Cta();
        _this.headerMainCta = new Cta();
        _this.headerMainMenu = new Menu();
        _this.headerEntryTitle = _this.propertyIsValid(data, 'headerEntryTitle') ? data.headerEntryTitle : '';
        _this.headerLogo = _this.propertyIsValid(data, 'headerLogo') ? data.headerLogo : '';
        _this.headerTopMenu = _this.propertyIsValid(data, 'headerTopMenu', 'object')
            ? new Menu(data.headerTopMenu)
            : new Menu();
        _this.headerTopCta = _this.propertyIsValid(data, 'headerTopCta', 'object') ? new Cta(data.headerTopCta) : new Cta();
        _this.headerMainCta = _this.propertyIsValid(data, 'headerMainCta', 'object') ? new Cta(data.headerMainCta) : new Cta();
        _this.headerMainMenu = _this.propertyIsValid(data, 'headerMainMenu', 'object')
            ? new Menu(data.headerMainMenu)
            : new Menu();
        return _this;
    }
    return Header;
}(Base));
export { Header };

import * as tslib_1 from "tslib";
import { Category } from './category';
import { Author } from './author';
import { Base, Collection } from './base';
var News = /** @class */ (function (_super) {
    tslib_1.__extends(News, _super);
    function News(data) {
        if (data === void 0) { data = null; }
        var _this = _super.call(this, data) || this;
        _this.newsTitle = '';
        _this.slug = '';
        _this.newsBlurb = '';
        _this.newsBody = '';
        _this.newsThumbnailImage = '';
        _this.newsImage = '';
        _this.category = new Category();
        _this.tags = '';
        _this.modelType = '';
        _this.author = new Author();
        _this.sectionType = '';
        _this.newsTitle = _this.propertyIsValid(data, 'newsTitle') ? data.newsTitle : '';
        _this.slug = _this.propertyIsValid(data, 'slug') ? data.slug : '';
        _this.newsBlurb = _this.propertyIsValid(data, 'newsBlurb') ? data.newsBlurb : '';
        _this.newsBody = _this.propertyIsValid(data, 'newsBody') ? data.newsBody : '';
        _this.newsThumbnailImage = _this.propertyIsValid(data, 'newsThumbnailImage') ? data.newsThumbnailImage : '';
        _this.newsImage = _this.propertyIsValid(data, 'newsImage') ? data.newsImage : '';
        _this.category = _this.propertyIsValid(data, 'category', 'object') ? new Category(data.category) : new Category();
        _this.tags = _this.propertyIsValid(data, 'tags') ? data.tags : '';
        _this.modelType = _this.propertyIsValid(data, 'modelType') ? data.modelType : '';
        _this.author = _this.propertyIsValid(data, 'author', 'object') ? new Author(data.author) : new Author();
        _this.sectionType = _this.propertyIsValid(data, 'sectionType') ? data.sectionType : '';
        _this.metaData = _this.propertyIsValid(data, 'metaData', 'object') ? data.metaData : {};
        return _this;
    }
    return News;
}(Base));
export { News };
var NewsCollection = /** @class */ (function (_super) {
    tslib_1.__extends(NewsCollection, _super);
    function NewsCollection(data) {
        if (data === void 0) { data = null; }
        var _this = _super.call(this, data) || this;
        _this.data = [];
        _this.data = _this.dataHasKey(data, 'data') ? data.data.map(function (item) { return new News(item); }) : [];
        return _this;
    }
    return NewsCollection;
}(Collection));
export { NewsCollection };

import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError } from 'rxjs/operators';
var SurveyService = /** @class */ (function (_super) {
    tslib_1.__extends(SurveyService, _super);
    function SurveyService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SurveyService.prototype.loadSurveyData = function (milestoneGroupId, listingId) {
        var url = this.url + 'survey/milestone-group/' + milestoneGroupId + '/listing/' + listingId;
        // tslint:disable-next-line:max-line-length
        return this.http.get(url, { headers: this.getHeaders() }).pipe(catchError(this.handleError('SurveyService::loadSurveyData, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    SurveyService.prototype.loadSurveyType = function (milestoneGroupId) {
        var url = this.url + 'survey-type/' + milestoneGroupId;
        // tslint:disable-next-line:max-line-length
        return this.http.get(url, { headers: this.getHeaders() }).pipe(catchError(this.handleError('SurveyService::loadSurveyType, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    SurveyService.prototype.loadSurveyor = function () {
        var url = this.url + 'surveyor';
        // tslint:disable-next-line:max-line-length
        return this.http.get(url, { headers: this.getHeaders() }).pipe(catchError(this.handleError('SurveyService::loadSurveyor, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    SurveyService.prototype.createSurveyType = function (data) {
        var url = this.url + 'survey-type';
        // tslint:disable-next-line:max-line-length
        return this.http.post(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('SurveyService::createSurveyType, url=' + url, {})));
    };
    SurveyService.prototype.createSurveyor = function (data) {
        var url = this.url + 'surveyor';
        // tslint:disable-next-line:max-line-length
        return this.http.post(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('SurveyService::createSurveyor, url=' + url, {})));
    };
    SurveyService.prototype.createSurvey = function (id, data) {
        var url = this.url + 'survey/' + id;
        return this.http.post(url, { data: data }, { headers: this.getHeaders() }).pipe(catchError(this.handleError('SurveyService::createSurvey, url=' + url + ', id=' + id, [])));
    };
    SurveyService.prototype.updateSurvey = function (id, data) {
        var url = this.url + 'survey/' + id;
        return this.http.put(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('SurveyService::updateSurvey, url=' + url + ', id=' + id, [])));
    };
    SurveyService.prototype.deleteSurvey = function (id) {
        var url = this.url + 'survey/' + id;
        return this.http.delete(url, { headers: this.getHeaders() }).pipe(catchError(this.handleError('SurveyService::deleteSurvey, url=' + url + ', id=' + id, [])));
    };
    return SurveyService;
}(ApiService));
export { SurveyService };

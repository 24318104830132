import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, map, retryWhen } from 'rxjs/operators';
var BranchService = /** @class */ (function (_super) {
    tslib_1.__extends(BranchService, _super);
    function BranchService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BranchService.prototype.teams = function (options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'teams' + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('BranchService::teams, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    BranchService.prototype.get = function (id, options) {
        if (options === void 0) { options = {}; }
        var url = this.url + 'branch/' + id + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('BranchService::get, url=' + url + ', id=' + id, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    BranchService.prototype.filter = function (filters, options) {
        if (options === void 0) { options = {}; }
        if (!filters.hasOwnProperty('rows')) {
            filters.rows = 1500;
        }
        var url = this.url + 'branch/filter' + ApiService.httpBuildQuery(options);
        return this.http.post(url, filters, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('BranchService::filter, url=' + url + ', filters=' + JSON.stringify(filters), {}, {
            url: url,
            method: 'POST',
            headers: this.getHeadersData(),
            data: filters,
        })), map(function (response) { return response.data; }));
    };
    BranchService.prototype.workflow = function (id) {
        var url = this.url + 'branch/' + id + '/workflow';
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('BranchService::workflow, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    BranchService.prototype.users = function (id) {
        var url = this.url + 'branch/' + id + '/relationships/users';
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('BranchService::users, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    BranchService.prototype.telephoneUpdate = function (id, attributes) {
        var url = this.url + 'branch/' + id + '/telephone';
        return this.http.put(url, attributes, { headers: this.getHeaders() }).pipe(
        // retryWhen(this.retryOnSystemFailure),
        catchError(this.handleError('BranchService::users, url=' + url, {})));
    };
    return BranchService;
}(ApiService));
export { BranchService };

<header class="header" *ngIf="content" [ngClass]="{'is-active-canvas': isMenuOpened}">

  <!--Desktop-->
  <div class="container">
    <nav class="navbar is-hidden-touch" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item" routerLink="{{ getUrlHome() }}" id="header-desktop-home">
          <img src="{{ headerLogo }}" alt="View My Chain Logo">
        </a>
      </div>
      <div class="navbar-menu">
        <!-- navbar start, navbar end -->
        <div class="navbar-start">
          <!-- navbar items -->
          <a class="navbar-item" *ngIf="isClientUser" routerLink="/users/home" routerLinkActive="is-active" id="header-desktop-user-home">Home</a>
          <a class="navbar-item" *ngIf="canViewMyProperties" (click)="redirectToNewListings()" routerLinkActive="is-active" id="header-desktop-listings">My Properties</a>
          <a class="navbar-item" *ngIf="canViewMwuDashboard" routerLink="/dashboard" routerLinkActive="is-active" id="header-desktop-dashboard">
            <span>Dashboard</span>
            <span class="tag is-success is-rounded new-tag">New</span>
          </a>
          <a class="navbar-item" *ngIf="canAddListing" routerLink="/listing/create" routerLinkActive="is-active" id="header-desktop-add-listing">Add a Listing</a>
          <a class="navbar-item" *ngIf="canAttachListing && (isAdmin || isProxyUser)" (click)="redirectToProxyAttachListing()" routerLinkActive="is-active" id="header-desktop-proxy-attach-listing">Attach a Listing</a>
          <a class="navbar-item" *ngIf="canReportViewConnells" (click)="redirectToNewReports()" routerLinkActive="is-active" id="header-desktop-reports">Reports</a>
          <a class="navbar-item" *ngIf="canAttachListing && isCompanyConveyancer" (click)="redirectToCompanyConveyancerAttachListing()" routerLinkActive="is-active" id="header-desktop-company-conveyancer-attach-listing">Attach a Listing</a>
          <a class="navbar-item" *ngIf="canClientRead" routerLink="/clients" [class.is-active]="clientRouteActive" id="header-desktop-proxy-clients">Clients</a>
          <a class="navbar-item" *ngIf="canClientUserRead" routerLink="/client-users" routerLinkActive="is-active" id="header-desktop-proxy-clients-users">Client Users</a>
          <a class="navbar-item" *ngIf="canViewConsumerForm" (click)="redirectToNewConsumerForm()" routerLinkActive="is-active" id="header-desktop-consumer-create">Funding Information</a>
          <a class="navbar-item" *ngIf="canClientContactRead" routerLink="/clients/contact-management" [class.is-active]="clientContactRouteActive" id="header-desktop-create-contact">Contact Management</a>
          <a class="navbar-item" *ngIf="canReadTask" (click)="redirectToMyTasks()" id="header-desktop-my-tasks">My Tasks</a>
        </div>

        <div class="navbar-end">
          <!-- navbar items -->

          <ng-template [ngIf]="showBrochure">
            <ng-container *ngFor="let item of content.headerMainMenu.menuItems">
              <a *ngIf="item.isExternal" href="{{ item.menuUrl }}" target="_blank" rel="noopener" class="navbar-item" id="header-desktop-menu-link-external-{{ item.menuName }}">
                {{ item.menuName }}
              </a>
              <a *ngIf="!item.isExternal" routerLink="{{ item.menuUrl }}" class="navbar-item" routerLinkActive="is-active"
                 id="header-desktop-menu-link-internal-{{ item.menuName }}">
                {{ item.menuName }}
              </a>
            </ng-container>
          </ng-template>

          <!--Phone Number-->
<!--          <a class="navbar-item is-contact-link" href="tel:{{ content.headerTopCta.ctaUrl }}" id="header-desktop-tel">-->
<!--            <span class="icon"><i class="fa fa-phone"></i></span>-->
<!--            <span class="text">{{ content.headerTopCta.ctaText }}</span>-->
<!--          </a>-->

          <!--Launch Intercom-->
          <!-- <a class="navbar-item" routerLink="/contact-us" routerLinkActive="is-active" id="header-desktop-contact">
            <span class="icon"><i class="fa fa-user-circle"></i> </span>
            <span class="text">Contact Us</span>
          </a> -->

          <a class="navbar-item" routerLink="/support" routerLinkActive="is-active" id="header-desktop-support" #supportLink (click)="reloadPage(supportLink)">
            <span class="icon"><i class="fa fa-question-circle"></i> </span>
            <span class="text">Support</span>
          </a>
<!--          <a class="navbar-item" (click)="showContact()" id="header-desktop-launch-intercom">-->
<!--            <span class="icon"><i class="fa fa-question-circle"></i> </span>-->
<!--            <span class="text">Help</span>-->
<!--          </a>-->

          <!--Drop Down-->
          <a class="navbar-item has-dropdown" [ngClass]="{ 'is-active': openUserMenu}" (click)="toggleUserMenu()" (blur)="openUserMenu = false"
             id="header-desktop-toggle-user-menu">
            <div class="navbar-link">
              <span class="icon"><i class="fa fa-user"></i> </span>
              <span>{{currentUser.attributes.first_name}}</span>
            </div>

            <div class="navbar-dropdown is-right">
              <!-- Other navbar items -->
              <a class="navbar-item" *ngIf="canViewMwuDashboard" routerLink="/dashboard" routerLinkActive="is-active" (click)="toggleUserMenu()" id="header-desktop-dashboard-dropdown">
                Dashboard
              </a>
              <a *ngIf="canManageBranches && !isCompanyConveyancer && !isIndividualConveyancer !isIndividualConsumer" class="navbar-item" routerLink="/branches" routerLinkActive="is-active" (click)="toggleUserMenu()" id="header-desktop-branches">
                Branches
              </a>
              <a class="navbar-item" routerLinkActive="is-active" (click)="redirectToNewProfileView()" (click)="toggleUserMenu()" id="header-desktop-profile">
                Profile
              </a>
              <hr *ngIf="canViewDashboard == true" class="navbar-divider">
              <a class="navbar-item" (click)="logout()" id="header-desktop-logout">
                Logout
              </a>
            </div>
          </a>
        </div>
      </div>
    </nav>
  </div>

  <!--Mobile-->
  <div class="header-mobile is-hidden-desktop">
    <nav class="container nav-mobile navbar">
      <div class="navbar-brand">
        <a class='navbar-item' routerLink="{{ getUrlHome() }}" id="header-mobile-home">
          <img src="{{ headerLogo }}" alt="View My Chain Logo">
        </a>
        <div class="navbar-burger" (click)="toggleMenu()" [ngClass]="{'is-active': isMenuOpened}" id="header-mobile-hamburger">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </nav>
    <aside class="menu menu-canvas" [ngClass]="{'is-active': isMenuOpened}" style="z-index: 1000;">
      <div class="nav-primary">
        <div class="navbar-actions">
          <ul>
            <li>
              <a class="button is-primary is-inverted has-text-left" *ngIf="isClientUser" routerLink="/users/home" routerLinkActive="is-active" id="header-desktop-user-home">Home</a>
            </li>
            <li *ngIf="canViewMyProperties">
              <a class="button is-primary is-inverted has-text-left" (click)="redirectToNewListings()" routerLinkActive="is-active" id="header-mobile-listings">My Properties</a>
            </li>
            <li *ngIf="canViewMwuDashboard">
              <a class="button is-primary is-inverted has-text-left" routerLink="/dashboard" routerLinkActive="is-active" id="header-mobile-dashboard">Dashboard</a>
            </li>
            <li *ngIf="canAddListing">
              <a class="button is-primary is-inverted has-text-left" routerLink="/listing/create" routerLinkActive="is-active" id="header-mobile-add-listing">Add a Listing</a>
            </li>
            <li *ngIf="canReportViewConnells">
              <a class="button is-primary is-inverted has-text-left" (click)="redirectToNewReports()" routerLinkActive="is-active" id="header-mobile-reports">Reports</a>
            </li>
            <li *ngIf="canViewConsumerForm">
              <a class="button is-primary is-inverted has-text-left" (click)="redirectToNewConsumerForm()" id="header-mobile-consumer-create">Funding Information</a>
            </li>
            <li *ngIf="canClientUserRead">
              <a class="button is-primary is-inverted has-text-left" routerLink="/client-users/" routerLinkActive="is-active" id="header-mobile-client-users">Client Users</a>
            </li>
            <li *ngIf="canAttachListing && (isAdmin || isProxyUser)">
              <a class="button is-primary is-inverted has-text-left" (click)="redirectToProxyAttachListing()" routerLinkActive="is-active"
                 id="header-mobile-proxy-attach-listing">Attach a Listing</a>
            </li>
            <li *ngIf="canAttachListing && isCompanyConveyancer">
              <a class="button is-primary is-inverted has-text-left" (click)="redirectToCompanyConveyancerAttachListing()" routerLinkActive="is-active"
                 id="header-mobile-company-conveyancer-attach-listing">Attach a Listing</a>
            </li>
            <li *ngIf="canClientContactRead">
              <a class="button is-primary is-inverted has-text-left" routerLink="/clients/contact-management" [class.is-active]="clientContactRouteActive" id="header-mobile-create-contact">Contact Management</a>
            </li>
            <li>
              <a class="button is-primary is-inverted has-text-left" (click)="redirectToNewProfileView()" routerLinkActive="is-active" id="header-mobile-profile">Profile</a>
            </li>
            <li>
              <a *ngIf="canManageBranches && !isCompanyConveyancer && !isIndividualConveyancer !isIndividualConsumer" class="button is-primary is-inverted has-text-left" routerLink="/branches" routerLinkActive="is-active" id="header-mobile-branches">Branches</a>
            </li>
            <li *ngIf="canReadTask">
              <a class="button is-primary is-inverted has-text-left" routerLink="{{urlMyTasks}}" routerLinkActive="is-active" id="header-mobile-my-tasks">My Tasks</a>
            </li>
          </ul>
        </div>
        <hr>
        <vmc-menu [menu]="content.headerMainMenu" [itemClass]="'button is-white'" [itemType]="'header'"></vmc-menu>
      </div>
      <hr>
      <div class="navbar-actions">
        <ul>
<!--          <li>-->
<!--            <a class="button is-primary is-inverted has-text-left" href="tel:{{ content.headerTopCta.ctaUrl }}" id="header-mobile-tel">-->
<!--              {{ content.headerTopCta.ctaText }}-->
<!--            </a>-->
<!--          </li>-->
          <!-- <li>
            <a class="button is-primary is-inverted has-text-left" routerLink="/contact-us">
              <span>Contact Us</span>
            </a>
          </li> -->
          <li>
            <a class="button is-primary is-inverted has-text-left" routerLink="/support" routerLinkActive="is-active" #supportLink (click)="reloadPage(supportLink)">
              <!-- <span class="icon"><i class="fa fa-question-circle"></i> </span> -->
              <span class="text">Support</span>
            </a>
          </li>
<!--          <li>-->
<!--            <a class="button is-primary is-inverted has-text-left" (click)="showContact()" id="header-mobile-launch-intercom">-->
<!--              <span>Help</span>-->
<!--            </a>-->
<!--          </li>-->
          <li>
            <a class="button is-primary is-inverted has-text-left" (click)="logout()" id="header-mobile-logout">Logout</a>
          </li>
        </ul>

      </div>
    </aside>
  </div>
  <div class="overlay is-hidden is-hidden-tablet"></div>
</header>

import * as tslib_1 from "tslib";
import { Menu } from './menu';
import { Partners } from './partners';
import { Base } from './base';
var Footer = /** @class */ (function (_super) {
    tslib_1.__extends(Footer, _super);
    function Footer(data) {
        if (data === void 0) { data = null; }
        var _this = _super.call(this, data) || this;
        _this.footerEntryTitle = '';
        _this.footerPartners = new Partners();
        _this.footerMenus = [];
        _this.footerFormShortCode = '';
        _this.socialNetworks = new Menu();
        _this.footerStrapline = '';
        _this.footerMenus = _this.dataHasKey(data, 'footerMenus') ? data['footerMenus'].map(function (item) { return new Menu(item); }) : [];
        _this.footerEntryTitle = _this.propertyIsValid(data, 'footerEntryTitle') ? data.footerEntryTitle : '';
        _this.footerPartners = _this.propertyIsValid(data, 'footerPartners', 'object')
            ? new Partners(data.footerPartners)
            : new Partners();
        _this.footerFormShortCode = _this.propertyIsValid(data, 'footerFormShortCode') ? data.footerFormShortCode : '';
        _this.socialNetworks = _this.propertyIsValid(data, 'socialNetworks', 'object')
            ? new Menu(data.socialNetworks)
            : new Menu();
        _this.footerStrapline = _this.propertyIsValid(data, 'footerStrapline') ? data.footerStrapline : '';
        return _this;
    }
    return Footer;
}(Base));
export { Footer };

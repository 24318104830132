import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError } from 'rxjs/operators';
var DelayService = /** @class */ (function (_super) {
    tslib_1.__extends(DelayService, _super);
    function DelayService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DelayService.prototype.create = function (data) {
        var url = this.url + 'delay';
        return this.http.post(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('DelayService::create, url=' + url + ', data=' + JSON.stringify(data))));
    };
    DelayService.prototype.deleteDelay = function (delayId) {
        var url = this.url + "delay/" + delayId;
        return this.http.delete(url, { headers: this.getHeaders() }).pipe(catchError(this.handleError('DelayService::delete, url=' + url)));
    };
    DelayService.prototype.show = function (listingId, milestoneType) {
        var url = this.url + 'listing/' + listingId + '/milestone-type/' + milestoneType + '/delay';
        return this.http.get(url, { headers: this.getHeaders() }).pipe(catchError(this.handleError('DelayService::show, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    DelayService.prototype.forListing = function (listingId) {
        var url = this.url + 'listing/' + listingId + '/relationships/delays';
        return this.http.get(url, { headers: this.getHeaders() }).pipe(catchError(this.handleError('DelayService::forListing, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    DelayService.prototype.chain = function (listingId) {
        var url = this.url + 'delay/chain/' + listingId;
        return this.http.get(url, { headers: this.getHeaders() }).pipe(catchError(this.handleError('DelayService::chain, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    return DelayService;
}(ApiService));
export { DelayService };

import * as tslib_1 from "tslib";
import { Page } from './page';
import { MenuItem } from './menu-item';
import { Base } from './base';
var Menu = /** @class */ (function (_super) {
    tslib_1.__extends(Menu, _super);
    function Menu(data) {
        if (data === void 0) { data = null; }
        var _this = _super.call(this, data) || this;
        _this.menuEntryTitle = '';
        _this.menuItems = [];
        _this.menuClass = '';
        _this.menuEntryTitle = _this.propertyIsValid(data, 'menuEntryTitle', 'string') ? data.menuEntryTitle : '';
        _this.menuClass = _this.propertyIsValid(data, 'menuClass', 'string') ? data.menuClass : '';
        if (_this.dataHasKey(data, 'menuItems')) {
            _this.menuItems = data['menuItems'].map(function (item) {
                if (item.submenuOption) {
                    var option = (item.submenuOption || []).find(function (val) {
                        return val.env === 'dev';
                    });
                    if (option) {
                        var fake = option.menuItem;
                        if (fake.sysMeta && fake.sysMeta.contentType === 'menu') {
                            fake.menuName = item.name;
                        }
                        if (fake.sysMeta && fake.sysMeta.contentType === 'page') {
                            fake.pageTitle = item.name;
                        }
                        item = fake;
                    }
                }
                if (item.sysMeta && item.sysMeta.contentType === 'menu') {
                    return new MenuItem(item);
                }
                if (item.sysMeta && item.sysMeta.contentType === 'page') {
                    return new Page(item);
                }
                return null;
            }).filter(function (x) { return x; });
        }
        else {
            _this.menuItems = [];
        }
        return _this;
    }
    return Menu;
}(Base));
export { Menu };

import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Sentry from '@sentry/browser';
import { of } from 'rxjs/internal/observable/of';
import { Subject } from 'rxjs/internal/Subject';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { UserService } from '../api-services/user.service';
import { User } from '../models/user';
import { ErrorHandlerService } from '../shared/error-handler/error-handler.service';
// import {IntercomService} from '../shared/intercom/intercom.service';
import { LogOutService } from './log-out.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../api-services/user.service";
import * as i3 from "./log-out.service";
import * as i4 from "../shared/error-handler/error-handler.service";
import * as i5 from "ngx-cookie-service";
import * as i6 from "@angular/router";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(http, 
    // private intercomService: IntercomService,
    userService, logOut, errors, cookieService, router) {
        this.http = http;
        this.userService = userService;
        this.logOut = logOut;
        this.errors = errors;
        this.cookieService = cookieService;
        this.router = router;
        this.user = new User();
        this.permissions = [];
        this.teams = [];
        this.roles = [];
        this.retrievingCurrentUser = true;
        this.$user = new Subject();
    }
    AuthenticationService.prototype.init = function () {
        var _this = this;
        if (!this.user.id && this.accessToken()) {
            this.retrievingCurrentUser = true;
            this.userService.current({ includes: 'permissions,teams,roles' }).subscribe(function (user) { return _this.setUpUser(user); }, function (error) { return null; });
        }
        else {
            this.retrievingCurrentUser = false;
        }
    };
    AuthenticationService.prototype.setUpUser = function (user) {
        this.retrievingCurrentUser = false;
        this.user = user.data;
        if (user.included) {
            this.permissions = user.included.filter(function (include) { return include.type === 'permission'; });
            this.teams = user.included.filter(function (include) { return include.type === 'team'; });
            this.roles = user.included.filter(function (include) { return include.type === 'role'; });
            /*if (environment.name !== 'development') {
              const hash = user.included.find(include => include.type === 'hash');
              if (hash) {
                this.intercomService.shutdownAndReboot(hash['attributes']['hash'], user.data);
              }
            }*/
        }
        this.$user.next(this.user);
    };
    AuthenticationService.prototype.refreshToken = function () {
        var _this = this;
        var headers = new HttpHeaders().set('Content-Type', 'application/json');
        var url = environment.vmc_api_url + 'refresh';
        return this.http.post(url, { headers: headers, withCredentials: true }).pipe(switchMap(function (response) {
            if (environment.env == 'dev') {
                localStorage.setItem('access_token', response['access_token']);
            }
            else {
                _this.cookieService.set('accessToken' + '-' + environment.env, response['access_token'], 1, '/', environment.share_cookies_domain);
            }
            return of(response);
        }));
    };
    AuthenticationService.prototype.login = function (email, password) {
        var _this = this;
        var headers = new HttpHeaders().set('Content-Type', 'application/json');
        var url = environment.vmc_api_url + 'login?includes=permissions,teams,roles';
        var data = { email: email, password: password };
        return this.http.post(url, JSON.stringify(data), { headers: headers, withCredentials: true }).pipe(map(function (response) {
            localStorage.setItem('access_token', response['access_token']);
            localStorage.setItem('expires_in', response['expires_in']);
            localStorage.setItem('hash', response['hash']);
            _this.setUpUser(response['user']);
            /*if (environment.name !== 'development') {
              this.intercomService.shutdownAndReboot(response['hash'], this.user);
            }*/
            Sentry.configureScope(function (scope) {
                scope.setUser({ email: _this.user.attributes.email });
            });
            return response;
        }), catchError(this.errors.dontReport([402, 401, 429]).handle('AuthenticationService::login', [], {
            url: url,
            data: data,
            method: 'POST',
            headers: this.getHeadersData(headers)
        })));
    };
    AuthenticationService.prototype.getHeadersData = function (headers) {
        var keys = headers.keys();
        var headerData = {};
        keys.map(function (key) {
            headerData[key] = headers.get(key);
        });
        return headerData;
    };
    AuthenticationService.prototype.logout = function () {
        this.logOut.handle(this.redirectUrl);
        this.permissions = [];
        this.teams = [];
        this.roles = [];
    };
    AuthenticationService.prototype.isLoggedIn = function () {
        if (environment.env == 'dev') {
            return !!localStorage.getItem('access_token');
        }
        else {
            return this.cookieService.check('accessToken' + '-' + environment.env);
        }
    };
    AuthenticationService.prototype.sendResetLink = function (email) {
        var headers = new HttpHeaders().set('Content-Type', 'application/json');
        var url = environment.vmc_api_url + 'password/email';
        // tslint:disable-next-line:max-line-length
        return this.http.post(url, JSON.stringify({ email: email }), { headers: headers }).pipe(catchError(this.errors.handle('AuthenticationService::sendResetLink, url=' + url + ', email=' + email, [])));
    };
    AuthenticationService.prototype.resetPassword = function (token, encryptedEmail, password, password_confirmation) {
        var headers = new HttpHeaders().set('Content-Type', 'application/json');
        var body = JSON.stringify({
            token: token,
            encryptedEmail: encryptedEmail,
            password: password,
            password_confirmation: password_confirmation,
        });
        var url = environment.vmc_api_url + 'password/reset';
        return this.http.post(url, body, { headers: headers }).pipe(catchError(this.errors.handle('AuthenticationService::resetPassword, url=' + url + ', email=' + encryptedEmail, [])));
    };
    AuthenticationService.prototype.can = function (permission, all) {
        var _this = this;
        if (all === void 0) { all = false; }
        return this.currentUser().pipe(map(function () {
            if (_this.user.attributes.type == 'admin') {
                return true;
            }
            if (typeof permission == 'string') {
                return _this.permissions.findIndex(function (item) { return item.attributes.name === permission; }) != -1;
            }
            if (Array.isArray(permission)) {
                var results = _this.permissions.filter(function (value) {
                    return permission.indexOf(value.attributes.name) != -1;
                }).length;
                return all ? results == permission.length : results > 0;
            }
            return false;
        }));
    };
    AuthenticationService.prototype.hasRole = function (role, all) {
        var _this = this;
        if (all === void 0) { all = false; }
        return this.currentUser().pipe(map(function () {
            if (typeof role == 'string') {
                return _this.roles.findIndex(function (item) { return item.attributes.name === role; }) != -1;
            }
            if (Array.isArray(role)) {
                var results = _this.roles.filter(function (value) {
                    return role.indexOf(value.attributes.name) != -1;
                }).length;
                return all ? results == role.length : results > 0;
            }
            return false;
        }));
    };
    AuthenticationService.prototype.isMwu = function () {
        var _this = this;
        return this.currentUser().pipe(map(function () { return Boolean(_this.teams.find(function (team) { return team.attributes.branch_id == -1006; })); }));
    };
    AuthenticationService.prototype.isUserOfBranch = function (branch_id) {
        var _this = this;
        return this.currentUser().pipe(map(function () { return Boolean(_this.teams.find(function (team) { return team.attributes.branch_id == branch_id; })); }));
    };
    AuthenticationService.prototype.branch_ids = function () {
        var branch_ids = [];
        this.teams.map(function (team) { return branch_ids.push(team.attributes.branch_id.toString()); });
        return branch_ids;
    };
    AuthenticationService.prototype.canUpdateListing = function (listing) {
        var rt = false;
        var isUserOfBranch = false;
        var isUserTrustworthy = false;
        this.isUserOfBranch(listing.attributes.branch_id).subscribe(function (res) { return isUserOfBranch = res; });
        this.can('user-trustworthy').subscribe(function (res) { return isUserTrustworthy = res; });
        if (listing.attributes.users_count <= 0 || isUserOfBranch || isUserTrustworthy) {
            rt = true;
        }
        return of(Boolean(rt));
    };
    AuthenticationService.prototype.canUpdateMilestones = function (listing) {
        var canUpdateMilestone = false;
        var canUpdateListing = true;
        this.can('listing-update-milestones').subscribe(function (res) { return canUpdateMilestone = res; });
        this.canUpdateListing(listing).subscribe(function (res) { return canUpdateListing = res; });
        return of(Boolean(canUpdateMilestone && canUpdateListing));
    };
    AuthenticationService.prototype.accessToken = function () {
        if (environment.env == 'dev') {
            return localStorage.getItem('access_token');
        }
        else {
            return this.cookieService.get('accessToken' + '-' + environment.env);
        }
    };
    AuthenticationService.prototype.currentUser = function () {
        if (this.retrievingCurrentUser) {
            return this.$user.asObservable().pipe(first());
        }
        return of(this.user).pipe(first());
    };
    AuthenticationService.prototype.getRedirectPage = function () {
        var canViewMyProperties = false;
        var canViewMwuDashboard = false;
        var canReportViewConnells = false;
        var canClientRead = false;
        var canClientUserRead = false;
        var canViewConsumerForm = false;
        var canClientContactRead = false;
        this.permissionRedirect('view-my-properties').subscribe(function (can) { canViewMyProperties = can; });
        this.permissionRedirect('view-mwu-dashboard').subscribe(function (can) { return (canViewMwuDashboard = can); });
        this.permissionRedirect('report-view-connells').subscribe(function (can) { return (canReportViewConnells = can); });
        this.permissionRedirect('client-read').subscribe(function (can) { return (canClientRead = can); });
        this.permissionRedirect('client-user-read').subscribe(function (can) { return (canClientUserRead = can); });
        this.permissionRedirect('view-consumer-form').subscribe(function (can) { return (canViewConsumerForm = can); });
        this.permissionRedirect('client-contact-read').subscribe(function (can) { return (canClientContactRead = can); });
        if (canViewMyProperties) {
            return window.location.href = environment.vmc_webapp2_url + 'listings';
        }
        if (canViewMwuDashboard) {
            return this.router.navigate(['/dashboard']);
        }
        if (canReportViewConnells) {
            return this.router.navigate(['/reports']);
        }
        if (canClientRead) {
            return this.router.navigate(['/clients']);
        }
        if (canClientUserRead) {
            return this.router.navigate(['/client-users']);
        }
        if (canViewConsumerForm) {
            return this.router.navigate(['/consumer/create']);
        }
        if (canClientContactRead) {
            return this.router.navigate(['/clients/contact-management']);
        }
        return this.router.navigate(['/profile']);
    };
    AuthenticationService.prototype.permissionRedirect = function (permission, all) {
        if (all === void 0) { all = false; }
        if (this.user.attributes.type == 'admin') {
            return of(true);
        }
        if (typeof permission == 'string') {
            return of(this.permissions.findIndex(function (item) { return item.attributes.name === permission; }) != -1);
        }
        if (Array.isArray(permission)) {
            var results = this.permissions.filter(function (value) {
                return permission.indexOf(value.attributes.name) != -1;
            }).length;
            return of(all ? results == permission.length : results > 0);
        }
        return of(false);
    };
    AuthenticationService.ngInjectableDef = i0.defineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.inject(i1.HttpClient), i0.inject(i2.UserService), i0.inject(i3.LogOutService), i0.inject(i4.ErrorHandlerService), i0.inject(i5.CookieService), i0.inject(i6.Router)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };

<div mat-dialog-content class="content">
    <span class="question">Are you sure you want to break this link?</span>
    <span class="address">
        <span>{{listing.seller.address.address_line_1}} </span>and
        <span> {{nextListing.seller.address.address_line_1}}</span>
    </span>
</div>
<div class="action">
  <button mat-button [mat-dialog-close]="true" id="break">Break Link</button>
  <button mat-button [mat-dialog-close]="false" id="cancel">Keep Link</button>
</div>

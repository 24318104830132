import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, retryWhen } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../shared/error-handler/error-handler.service";
var ListingMilestoneDetailService = /** @class */ (function (_super) {
    tslib_1.__extends(ListingMilestoneDetailService, _super);
    function ListingMilestoneDetailService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ListingMilestoneDetailService.prototype.all = function (id) {
        var url = this.url + "listing/" + id + "/milestone-detail";
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingMilestoneDetailService::filter, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    ListingMilestoneDetailService.ngInjectableDef = i0.defineInjectable({ factory: function ListingMilestoneDetailService_Factory() { return new ListingMilestoneDetailService(i0.inject(i1.HttpClient), i0.inject(i2.ErrorHandlerService)); }, token: ListingMilestoneDetailService, providedIn: "root" });
    return ListingMilestoneDetailService;
}(ApiService));
export { ListingMilestoneDetailService };

var ɵ0 = {
    parse: {
        dateInput: 'YYYY-MM-DD',
    },
    display: {
        dateInput: 'DD-MM-YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
var CustomMaterialModule = /** @class */ (function () {
    function CustomMaterialModule() {
    }
    return CustomMaterialModule;
}());
export { CustomMaterialModule };
export { ɵ0 };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./add-an-address.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/address-finder/address-finder.component.ngfactory";
import * as i3 from "../../shared/address-finder/address-finder.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "@angular/common";
import * as i6 from "./add-an-address.component";
import * as i7 from "../../api-services/listing.service";
import * as i8 from "../../api-services/contact.service";
import * as i9 from "@angular/router";
var styles_AddAnAddressComponent = [i0.styles];
var RenderType_AddAnAddressComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddAnAddressComponent, data: {} });
export { RenderType_AddAnAddressComponent as RenderType_AddAnAddressComponent };
function View_AddAnAddressComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vmc-address-finder", [], null, [[null, "onSelect"], [null, "onClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelect" === en)) {
        var pd_0 = (_co.setAddressId($event) !== false);
        ad = (pd_0 && ad);
    } if (("onClose" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_AddressFinderComponent_0, i2.RenderType_AddressFinderComponent)), i1.ɵdid(1, 114688, null, 0, i3.AddressFinderComponent, [], { defaultValue: [0, "defaultValue"] }, { onSelect: "onSelect", onClose: "onClose" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listing.data.attributes.address_postal_code; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AddAnAddressComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "help"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["id", "verify-listing-address-change"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resetAddress() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Edit"]))], null, null); }
function View_AddAnAddressComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "address-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selected_address; _ck(_v, 1, 0, currVal_0); }); }
function View_AddAnAddressComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["style", "margin-bottom: 8px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" To view chains and milestones please leave this listing overnight for them to build automatically. You can build chains yourself if you need to see them immediately. "]))], null, null); }
function View_AddAnAddressComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "help is-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors.address_id; _ck(_v, 1, 0, currVal_0); }); }
export function View_AddAnAddressComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "header", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "columns"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h3", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(4, 81920, null, 0, i4.MatDialogTitle, [[2, i4.MatDialogRef], i1.ElementRef, i4.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Add an address"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "button", [["class", "delete is-pulled-right"], ["id", "verify-listing-address-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 20, "section", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i4.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddAnAddressComponent_1)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddAnAddressComponent_2)), i1.ɵdid(13, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddAnAddressComponent_3)), i1.ɵdid(15, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddAnAddressComponent_4)), i1.ɵdid(17, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddAnAddressComponent_5)), i1.ɵdid(19, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 8, "p", [["class", "control"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 4, "button", [["class", "button is-primary is-medium"], ["id", "verify-listing-address-verify"], ["style", "margin-right: 1rem;"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.apply() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(23, { "is-loading": 0 }), (_l()(), i1.ɵeld(24, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Apply"])), (_l()(), i1.ɵeld(26, 0, null, null, 2, "button", [["class", "button is-medium"], ["id", "verify-listing-address-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_1 = !_co.addressId; _ck(_v, 11, 0, currVal_1); var currVal_2 = _co.addressId; _ck(_v, 13, 0, currVal_2); var currVal_3 = _co.addressId; _ck(_v, 15, 0, currVal_3); var currVal_4 = _co.addressId; _ck(_v, 17, 0, currVal_4); var currVal_5 = (_co.errors && _co.errors.address_id); _ck(_v, 19, 0, currVal_5); var currVal_7 = "button is-primary is-medium"; var currVal_8 = _ck(_v, 23, 0, _co.running); _ck(_v, 22, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).id; _ck(_v, 3, 0, currVal_0); var currVal_6 = !_co.addressId; _ck(_v, 21, 0, currVal_6); }); }
export function View_AddAnAddressComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vmc-add-an-address", [], null, null, null, View_AddAnAddressComponent_0, RenderType_AddAnAddressComponent)), i1.ɵdid(1, 114688, null, 0, i6.AddAnAddressComponent, [i4.MatDialogRef, i4.MAT_DIALOG_DATA, i7.ListingService, i8.ContactService, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddAnAddressComponentNgFactory = i1.ɵccf("vmc-add-an-address", i6.AddAnAddressComponent, View_AddAnAddressComponent_Host_0, {}, {}, []);
export { AddAnAddressComponentNgFactory as AddAnAddressComponentNgFactory };

import * as tslib_1 from "tslib";
var Model = /** @class */ (function () {
    function Model() {
    }
    Model.prototype.propertyIsValid = function (data, key, type) {
        if (type === void 0) { type = 'string'; }
        return this.keyExists(data, key) && typeof data[key] === type;
    };
    Model.prototype.keyExists = function (data, key) {
        return null !== data && typeof data === 'object' && data.hasOwnProperty(key);
    };
    Model.prototype.dataHasKey = function (data, key) {
        return null !== data && typeof data === 'object' && data.hasOwnProperty(key) && Array.isArray(data[key]);
    };
    return Model;
}());
export { Model };
var Base = /** @class */ (function (_super) {
    tslib_1.__extends(Base, _super);
    function Base(data) {
        if (data === void 0) { data = null; }
        var _this = _super.call(this) || this;
        _this.sysMeta = new BaseAttributes();
        _this.sysMeta = _this.propertyIsValid(data, 'sysMeta', 'object')
            ? new BaseAttributes(data.sysMeta)
            : new BaseAttributes();
        return _this;
    }
    return Base;
}(Model));
export { Base };
var BaseAttributes = /** @class */ (function (_super) {
    tslib_1.__extends(BaseAttributes, _super);
    function BaseAttributes(data) {
        if (data === void 0) { data = null; }
        var _this = _super.call(this) || this;
        _this.createdAt = '';
        _this.updatedAt = '';
        _this.contentType = '';
        _this.createdAt = _this.propertyIsValid(data, 'createdAt') ? data.createdAt : '';
        _this.updatedAt = _this.propertyIsValid(data, 'updatedAt') ? data.updatedAt : '';
        _this.contentType = _this.propertyIsValid(data, 'contentType') ? data.contentType : '';
        return _this;
    }
    return BaseAttributes;
}(Model));
export { BaseAttributes };
var Collection = /** @class */ (function (_super) {
    tslib_1.__extends(Collection, _super);
    function Collection(data) {
        if (data === void 0) { data = null; }
        var _this = _super.call(this) || this;
        _this.current_page = _this.propertyIsValid(data, 'current_page', 'number') ? data.current_page : 0;
        _this.first_page_url = _this.propertyIsValid(data, 'first_page_url') ? data.first_page_url : '';
        _this.from = _this.propertyIsValid(data, 'from', 'number') ? data.from : 0;
        _this.last_page = _this.propertyIsValid(data, 'last_page', 'number') ? data.last_page : 0;
        _this.last_page_url = _this.propertyIsValid(data, 'last_page_url') ? data.last_page_url : '';
        _this.next_page_url = _this.propertyIsValid(data, 'next_page_url') ? data.next_page_url : '';
        _this.path = _this.propertyIsValid(data, 'path') ? data.path : '';
        _this.per_page = _this.propertyIsValid(data, 'per_page', 'number') ? data.per_page : 0;
        _this.prev_page_url = _this.propertyIsValid(data, 'prev_page_url') ? data.prev_page_url : '';
        _this.to = _this.propertyIsValid(data, 'to', 'number') ? data.to : 0;
        _this.total = _this.propertyIsValid(data, 'total', 'number') ? data.total : 0;
        return _this;
    }
    return Collection;
}(Model));
export { Collection };

import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, retryWhen } from 'rxjs/operators';
var MilestoneChecklistService = /** @class */ (function (_super) {
    tslib_1.__extends(MilestoneChecklistService, _super);
    function MilestoneChecklistService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MilestoneChecklistService.prototype.filter = function (filters) {
        var url = this.url + "milestone_checklist/filter?includes=items";
        return this.http.post(url, filters, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('MilestoneChecklistService::filter, url=' + url + ', filters=' + JSON.stringify(filters), {}, {
            url: url,
            method: 'POST',
            headers: this.getHeadersData(),
            data: filters,
        })));
    };
    return MilestoneChecklistService;
}(ApiService));
export { MilestoneChecklistService };

import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, retryWhen } from 'rxjs/operators';
var RoleService = /** @class */ (function (_super) {
    tslib_1.__extends(RoleService, _super);
    function RoleService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RoleService.prototype.all = function (options) {
        var url = this.url + 'role' + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('RoleService::all, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    return RoleService;
}(ApiService));
export { RoleService };

import * as tslib_1 from "tslib";
import { Base } from './base';
var MenuItem = /** @class */ (function (_super) {
    tslib_1.__extends(MenuItem, _super);
    function MenuItem(data) {
        if (data === void 0) { data = null; }
        var _this = _super.call(this, data) || this;
        _this.menuName = '';
        _this.menuUrl = '';
        _this.menuItemClass = '';
        _this.menuItemType = '';
        _this.isExternal = false;
        _this.menuName = _this.propertyIsValid(data, 'menuName') ? data.menuName : '';
        _this.menuUrl = _this.propertyIsValid(data, 'menuUrl') ? data.menuUrl : '';
        _this.menuItemClass = _this.propertyIsValid(data, 'menuItemClass') ? data.menuItemClass : '';
        _this.menuItemType = _this.propertyIsValid(data, 'menuItemType') ? data.menuItemType : '';
        _this.isExternal = _this.menuItemType == 'external_link';
        return _this;
    }
    return MenuItem;
}(Base));
export { MenuItem };

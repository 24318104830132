import * as tslib_1 from "tslib";
import { Base } from './base';
var Author = /** @class */ (function (_super) {
    tslib_1.__extends(Author, _super);
    function Author(data) {
        if (data === void 0) { data = null; }
        var _this = _super.call(this, data) || this;
        _this.authorName = '';
        _this.authorAvatar = '';
        _this.authorName = _this.propertyIsValid(data, 'authorName') ? data.authorName : '';
        _this.authorAvatar = _this.propertyIsValid(data, 'authorAvatar') ? data.authorAvatar : '';
        return _this;
    }
    return Author;
}(Base));
export { Author };

import * as tslib_1 from "tslib";
import { Partner } from './partner';
import { Base } from './base';
var Partners = /** @class */ (function (_super) {
    tslib_1.__extends(Partners, _super);
    function Partners(data) {
        if (data === void 0) { data = null; }
        var _this = _super.call(this, data) || this;
        _this.partnersEntryTitle = '';
        _this.partnersBlockTitle = '';
        _this.partnersListing = [];
        _this.partnersListing = _this.dataHasKey(data, 'partnersListing')
            ? data['partnersListing'].map(function (item) { return new Partner(item); })
            : [];
        _this.partnersEntryTitle = _this.propertyIsValid(data, 'partnersEntryTitle') ? data.partnersEntryTitle : '';
        _this.partnersBlockTitle = _this.propertyIsValid(data, 'partnersBlockTitle') ? data.partnersBlockTitle : '';
        return _this;
    }
    return Partners;
}(Base));
export { Partners };

import { environment } from '../../environments/environment';
import { ErrorHandlerService } from '../shared/error-handler/error-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { mergeMap } from 'rxjs/operators';
import { range } from 'rxjs/internal/observable/range';
import { zip } from 'rxjs/internal/observable/zip';
import { timer } from 'rxjs/internal/observable/timer';
import { throwError } from 'rxjs/internal/observable/throwError';
var ApiService = /** @class */ (function () {
    function ApiService(http, error) {
        this.http = http;
        this.error = error;
        this.url = environment.vmc_api_url;
        this.headers = new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json');
    }
    ApiService.getAccessToken = function () {
        if (environment.env == 'dev') {
            return localStorage.getItem('access_token');
        }
        else {
            return this.getCookies('accessToken' + '-' + environment.env);
        }
    };
    ApiService.httpBuildQuery = function (params) {
        if (params === void 0) { params = {}; }
        if (typeof params === 'undefined' || typeof params !== 'object') {
            params = {};
            return params;
        }
        var query = '?';
        var index = 0;
        for (var i in params) {
            index++;
            var param = i;
            var value = params[i];
            if (index == 1) {
                query += param + '=' + value;
            }
            else {
                query += '&' + param + '=' + value;
            }
        }
        return query;
    };
    ApiService.prototype.getHeaders = function () {
        var headers = this.headers;
        return headers.set('Authorization', 'Bearer ' + ApiService.getAccessToken());
    };
    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     * @param config
     */
    ApiService.prototype.handleError = function (operation, result, config) {
        if (operation === void 0) { operation = 'operation'; }
        return this.error.handle(operation, result, config);
    };
    ApiService.prototype.retryOnSystemFailure = function (errors) {
        return zip(errors, range(1, 4)).pipe(mergeMap(function (_a) {
            var error = _a[0], i = _a[1];
            return (error.status == 400 || error.status == 500) && i < 4 ? timer(300) : throwError(error);
        }));
    };
    ApiService.prototype.getHeadersData = function () {
        var headers = this.getHeaders();
        var keys = headers.keys();
        var headerData = {};
        keys.map(function (key) {
            headerData[key] = headers.get(key);
        });
        return headerData;
    };
    ApiService.getCookies = function (cookieName) {
        var name = cookieName + '=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    };
    return ApiService;
}());
export { ApiService };

import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { catchError, retryWhen } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../shared/error-handler/error-handler.service";
var ListingListingMetaService = /** @class */ (function (_super) {
    tslib_1.__extends(ListingListingMetaService, _super);
    function ListingListingMetaService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ListingListingMetaService.prototype.save = function (listingId, data, options) {
        var url = this.url + 'listing/' + listingId + '/listing-meta' + ApiService.httpBuildQuery(options);
        return this.http.post(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ListingListingMetaService::save, url=' + url, {})));
    };
    ListingListingMetaService.prototype.get = function (listingId, options) {
        var url = this.url + 'listing/' + listingId + '/listing-meta' + ApiService.httpBuildQuery(options);
        return this.http.get(url, { headers: this.getHeaders() }).pipe(retryWhen(this.retryOnSystemFailure), catchError(this.handleError('ListingListingMetaService::get, url=' + url, {}, {
            url: url,
            method: 'GET',
            headers: this.getHeadersData(),
        })));
    };
    ListingListingMetaService.prototype.saveNewLender = function (data) {
        var url = this.url + 'lender';
        return this.http.post(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ListingListingMetaService::saveNewLender, url=' + url, {})));
    };
    ListingListingMetaService.prototype.saveNewBroker = function (data) {
        var url = this.url + 'broker';
        return this.http.post(url, data, { headers: this.getHeaders() }).pipe(catchError(this.handleError('ListingListingMetaService::saveNewBroker, url=' + url, {})));
    };
    ListingListingMetaService.ngInjectableDef = i0.defineInjectable({ factory: function ListingListingMetaService_Factory() { return new ListingListingMetaService(i0.inject(i1.HttpClient), i0.inject(i2.ErrorHandlerService)); }, token: ListingListingMetaService, providedIn: "root" });
    return ListingListingMetaService;
}(ApiService));
export { ListingListingMetaService };

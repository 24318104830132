/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./confirm.component";
var styles_ConfirmComponent = [i0.styles];
var RenderType_ConfirmComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmComponent, data: {} });
export { RenderType_ConfirmComponent as RenderType_ConfirmComponent };
export function View_ConfirmComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [["id", "confirm-ok"], ["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).dialogRef.close(i1.ɵnov(_v, 7).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onConfirm() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(7, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(8, 0, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [["id", "confirm-cancel"], ["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).dialogRef.close(i1.ɵnov(_v, 11).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onCancel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(11, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(12, 0, ["", ""]))], function (_ck, _v) { var currVal_4 = true; _ck(_v, 7, 0, currVal_4); var currVal_9 = false; _ck(_v, 11, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.question; _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 6).disabled || null); var currVal_2 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); var currVal_3 = (i1.ɵnov(_v, 7).ariaLabel || null); _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3); var currVal_5 = _co.confirmButtonText; _ck(_v, 8, 0, currVal_5); var currVal_6 = (i1.ɵnov(_v, 10).disabled || null); var currVal_7 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); var currVal_8 = (i1.ɵnov(_v, 11).ariaLabel || null); _ck(_v, 9, 0, currVal_6, currVal_7, currVal_8); var currVal_10 = _co.cancelButtonText; _ck(_v, 12, 0, currVal_10); }); }
export function View_ConfirmComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vmc-confirm", [], null, null, null, View_ConfirmComponent_0, RenderType_ConfirmComponent)), i1.ɵdid(1, 114688, null, 0, i8.ConfirmComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmComponentNgFactory = i1.ɵccf("vmc-confirm", i8.ConfirmComponent, View_ConfirmComponent_Host_0, {}, {}, []);
export { ConfirmComponentNgFactory as ConfirmComponentNgFactory };
